import { Navigate, Route, Routes } from 'react-router-dom';
import AlertsList from './alerts-list';

const AlertsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AlertsList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AlertsRouting;
