import { App, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { StockApiService } from 'core/api';
import { IAccessoryDao } from 'core/api/types';
import { IStockAccessoryDao } from 'core/api/types/stock.interface';
import { StockStatus, StockStatusData, StockStatusOptions } from 'core/constants/stock-status';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import {
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';

export interface IAddEditStockAccessoryFormOutput {
  manufacturer: string;
  accessoryName: string;
  serialNumber?: string;
  cost: number;
  warranty?: string;
  quantity: number;
  status?: StockStatus;
  orderedDate?: Dayjs;
  stockAddedDate?: Dayjs;
  location?: string;
}

interface IAddEditStockAccessoryForm {
  tableKey: string;
  stock?: IStockAccessoryDao;
}

const AddEditStockAccessoryForm = ({ tableKey, stock }: IAddEditStockAccessoryForm) => {
  const [form] = Form.useForm();
  const dialog = useDialog();
  const accessoryState = useSelector(OrganisationSettingsSlice.selectAccessories);
  const manufacturersList = [
    ...new Set(accessoryState?.data.filter((accessory) => !accessory.deleted).map((acc) => acc.manufacturer)),
  ];
  const { t } = useTranslation();
  const selectedManufacturer = useWatch('manufacturer', form);
  const [availableAccessories, setAvailableAccessories] = useState<string[]>([]);
  const selectedAccessory = useWatch('accessoryName', form);
  const [matchedAccessory, setMatchedAccessory] = useState<IDomainOrganisationDataType<IAccessoryDao>>();
  const selectedStatus = useWatch('status', form);
  const { userData } = useUserState();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const table = useTable(tableKey);
  const counts = useTable('stockManagementCounts');
  const creating = !stock;

  const fields: ISharedField[] = [
    {
      fieldKey: 'manufacturer',
      control: ControlType.Select,
      options: manufacturersList?.map((manufacturer) => ({ label: manufacturer, value: manufacturer })),
      label: t('stock_management.add_edit_stock.form.manufacturer'),
      required: true,
    },
    {
      fieldKey: 'accessoryName',
      control: ControlType.Select,
      options: availableAccessories.map((acc) => ({ label: acc, value: acc })),
      label: t('stock_management.add_edit_stock.form.accessory_name'),
      required: true,
      hidden: !selectedManufacturer,
    },
    {
      fieldKey: 'serialNumber',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.serial_number'),
      required: false,
      hidden: !matchedAccessory,
    },
    {
      fieldKey: 'cost',
      control: ControlType.NumberField,
      label: t('stock_management.add_edit_stock.form.cost'),
      required: false,
      hidden: !matchedAccessory,
    },
    {
      fieldKey: 'warranty',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.warranty'),
      required: false,
      hidden: !matchedAccessory,
    },
    {
      fieldKey: 'location',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.location'),
      required: false,
      hidden: !matchedAccessory,
    },
    {
      fieldKey: 'quantity',
      control: ControlType.NumberField,
      label: t('stock_management.add_edit_stock.form.quantity'),
      required: true,
      hidden: !matchedAccessory,
      min: 1,
    },
    {
      fieldKey: 'status',
      control: ControlType.Select,
      options: StockStatusOptions.map((status) => {
        const statusData = StockStatusData[status];
        return { label: t(statusData.translationLabelKey), value: statusData.value };
      }),
      label: t('stock_management.add_edit_stock.form.status'),
      required: true,
      hidden: !matchedAccessory || stock !== undefined,
    },
    {
      fieldKey: 'orderedDate',
      control: ControlType.DatePicker,
      maxDate: dayjs(),
      label: t('stock_management.add_edit_stock.form.ordered_date'),
      required: true,
      hidden: !matchedAccessory || !selectedStatus || selectedStatus === StockStatus.NEEDS_ORDERING,
      fullWidth: true,
    },
    {
      fieldKey: 'stockAddedDate',
      control: ControlType.DatePicker,
      maxDate: dayjs(),
      label: t('stock_management.add_edit_stock.form.stock_added_date'),
      required: true,
      hidden:
        !matchedAccessory ||
        !selectedStatus ||
        selectedStatus === StockStatus.NEEDS_ORDERING ||
        selectedStatus === StockStatus.ORDERED,
      fullWidth: true,
    },
  ];

  const resetFields = useCallback(
    (fields: string[]) => {
      fields.forEach((field) => form.setFieldsValue({ [field]: undefined }));
    },
    [form]
  );

  useEffect(() => {
    if (selectedManufacturer) {
      const accessories = [
        ...new Set(
          accessoryState?.data
            .filter((acc) => !acc.deleted && acc.manufacturer === selectedManufacturer)
            .map((acc) => acc.accessoryName)
        ),
      ];
      setAvailableAccessories(accessories);
      if (selectedAccessory && !accessories.includes(selectedAccessory)) {
        resetFields(['accessoryName']);
      }
    }
  }, [accessoryState?.data, form, resetFields, selectedAccessory, selectedManufacturer]);

  useEffect(() => {
    if (selectedAccessory) {
      const matchedAccessory = accessoryState?.data.find(
        (acc) => acc.manufacturer === selectedManufacturer && acc.accessoryName === selectedAccessory
      );
      setMatchedAccessory(matchedAccessory);
    }
  }, [accessoryState?.data, resetFields, selectedAccessory, selectedManufacturer]);

  useEffect(() => {
    if (matchedAccessory) {
      form.setFieldsValue({
        cost: matchedAccessory.unitCost,
      });
    }
  }, [form, matchedAccessory]);

  const submit = async (data: IAddEditStockAccessoryFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const { serialNumber, warranty, orderedDate, stockAddedDate, cost, location, status, ...rest } = data;
      const basePayload = {
        ...rest,
        updated: userTimestamp,
        accessoryUid: matchedAccessory!.uid,
        ...(location && { location }),
        ...(serialNumber && { serialNumber }),
        ...(warranty && { warranty }),
        ...(cost && { cost }),
        ...(orderedDate && { orderedDate: Timestamp.fromDate(orderedDate.toDate()) }),
        ...(stockAddedDate && { stockAddedDate: Timestamp.fromDate(stockAddedDate.toDate()) }),
      };
      if (creating) {
        await StockApiService.set({
          ...rest,
          ...basePayload,
          organisationUid: userData.organisationUid,
          type: 'accessory',
          uid: uuidv4(),
          created: userTimestamp,
          allocation: {
            patients: [],
            allocations: [],
          },
          status: status!,
        });
      } else {
        await StockApiService.update(stock!.uid, basePayload);
      }
      setSubmitting(false);
      dialog?.closeDialog();
      message.success(
        t(
          creating ? 'stock_management.add_edit_stock.create.success' : 'stock_management.add_edit_stock.update.success'
        )
      );
      table?.refreshTable();
      counts?.refreshTable();
    } catch (error) {
      setSubmitting(false);
      message.error(
        t(creating ? 'stock_management.add_edit_stock.create.error' : 'stock_management.add_edit_stock.update.error')
      );
      sentryCaptureException(error, creating ? 'Add accessory stock' : 'Update accessory stock', userData);
    }
  };

  return (
    <SharedForm<IAddEditStockAccessoryFormOutput>
      formInstance={form}
      className=''
      onFinish={submit}
      fields={fields}
      submitting={submitting}
      cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
      name='add-edit-accessory-stock-form'
      existingValue={
        stock
          ? {
              ...stock,
              orderedDate: dayjs(stock.orderedDate?.toDate()),
              stockAddedDate: dayjs(stock.stockAddedDate?.toDate()),
            }
          : {
              stockAddedDate: dayjs(),
            }
      }
    />
  );
};

export default AddEditStockAccessoryForm;
