import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import SharedButton from 'shared/button/button';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { SearchOutlined } from '@ant-design/icons';
import { useDialog } from 'core/providers/dialog-provider';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import { useTranslation } from 'react-i18next';
import { auth } from 'core/config/firebase';
import { signOut } from 'firebase/auth';

const Header = () => {
  const { userData } = useUserState();
  const dialog = useDialog();
  const { t } = useTranslation();

  return (
    <div className='basis-[60px] md:basis-[70px] grow-0 flex-shrink-0 border-b bg-white flex items-center justify-between md:justify-end px-4'>
      <SharedElementPermissionGuard requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENTS_READ]]}>
        {!userData?.isHearLinkAdmin && (
          <SharedButton
            labelKey='patients.patient_search'
            icon={<SearchOutlined />}
            onClick={() => dialog?.openDialog(<PatientSearchDialog />)}
          />
        )}
      </SharedElementPermissionGuard>

      <div className='flex flex-col items-end text-gray-800 ml-6'>
        <p className='body-sm'>{userData?.fullName}</p>
        <button className='body-sm opacity-60 hover:underline bg-transparent' onClick={() => signOut(auth)}>
          {t('common.log_out')}
        </button>
      </div>
    </div>
  );
};

export default Header;
