import { Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import SharedButton from 'shared/button/button';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useDialog } from 'core/providers/dialog-provider';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { AppointmentsApiService } from 'core/api';
import { useTranslation } from 'react-i18next';
import SetAppointmentOutcomeDialog from 'modules/appointments-calendar/set-appointment-outcome-dialog';
import { Permission } from 'core/constants/permission';
import { usePermissionsState } from 'core/providers/permissions-provider';

interface IAppointmentMenu {
  appointment: IAppointmentDao;
  className?: string;
  mode?: 'calendar' | 'list';
  refreshAfterAction?: () => void;
}

const AppointmentMenu = ({ appointment, className, mode = 'calendar', refreshAfterAction }: IAppointmentMenu) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useUserState();
  const dialog = useDialog();
  const location = useLocation();
  const { userPermissions } = usePermissionsState();

  const timestamp = getActionTimestampFromUser(userData);

  const items = [
    {
      disabled: appointment.cancelled,
      key: '2',
      label: t('calendar.dropdown.edit_appointment'),
      requiredPermissions: [Permission.APPOINTMENTS_UPDATE],
    },
    {
      disabled: appointment.cancelled,
      key: '3',
      label: t('calendar.dropdown.set_appointment_outcome'),
      requiredPermissions: [Permission.APPOINTMENTS_UPDATE],
    },
    {
      disabled: appointment.cancelled,
      key: '4',
      label: t('calendar.dropdown.cancel_appointment'),
      requiredPermissions: [Permission.APPOINTMENTS_UPDATE],
    },
    {
      key: '5',
      label: t('calendar.dropdown.delete_appointment'),
      requiredPermissions: [Permission.APPOINTMENTS_DELETE],
    },
  ];

  if (!location.pathname.includes('patients')) {
    items.unshift({
      key: '1',
      label: t('calendar.dropdown.view_patient'),
      disabled: false,
      requiredPermissions: [Permission.PATIENTS_READ],
    });
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case '1':
        navigate(`/patients/${appointment.patient.uid}/overview`);
        break;
      case '2':
        navigate(`/calendar/create?patient=${appointment.patient.uid}&appointment=${appointment.uid}`);
        break;
      case '3':
        dialog?.openDialog(<SetAppointmentOutcomeDialog appointment={appointment} />);
        break;
      case '4':
        dialog?.openDialog(
          <ConfirmActionDialog
            action={async () => {
              await AppointmentsApiService.update(appointment.uid, { cancelled: true, updated: timestamp });
              refreshAfterAction?.();
            }}
            actionButtonProps={{
              labelKey: 'common.submit',
              danger: true,
            }}
            title={t('dialog.cancel_appointment.title')}
            textContent={t('dialog.cancel_appointment.content')}
            successMessage={t('dialog.cancel_appointment.success')}
            errorMessage={t('dialog.cancel_appointment.error')}
          />
        );
        break;
      case '5':
        dialog?.openDialog(
          <ConfirmActionDialog
            action={async () => {
              await AppointmentsApiService.permDelete(appointment.uid);
              refreshAfterAction?.();
            }}
            actionButtonProps={{
              labelKey: 'common.delete',
              danger: true,
            }}
            title={t('dialog.delete_appointment.title')}
            textContent={t('dialog.delete_appointment.content')}
            successMessage={t('dialog.delete_appointment.success')}
            errorMessage={t('dialog.delete_appointment.error')}
          />
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={className}>
      <Dropdown
        menu={{
          items: items
            .filter((item) =>
              [[Permission.ORGANISATION_OWNER], item.requiredPermissions].some((permissions) =>
                permissions.every((permission) => userPermissions?.includes(permission))
              )
            )
            .map(({ requiredPermissions, ...rest }) => rest),
          onClick,
        }}
        placement='bottomRight'
        trigger={['click']}
      >
        <div>
          <SharedButton
            additionalStyle={mode === 'calendar' ? { color: 'white' } : {}}
            appearance={mode === 'calendar' ? 'link' : 'default'}
            icon={<EllipsisOutlined size={20} />}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default AppointmentMenu;
