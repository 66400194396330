import { App } from 'antd';
import clsx from 'clsx';
import { TransactionApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { ITransactionDao } from 'core/api/types/transaction.interface';
import { PaymentMethodData } from 'core/constants/payment-method';
import { TransactionTypeData } from 'core/constants/transaction-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditTransactionDialog from './add-edit-transaction-dialog';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import PatientFinancialWidget from '../patient-financials-widget';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'patients.patient.transactions.table.header.date',
    key: 'date',
    contentTemplateId: 'date',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.type',
    key: 'type',
    contentTemplateId: 'type',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.amount',
    key: 'amount',
    contentTemplateId: 'amount',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.method',
    key: 'method',
    contentTemplateId: 'method',
  },
  {
    labelKey: 'patients.patient.transactions.table.header.created',
    key: 'created',
    contentTemplateId: 'created',
    width: 300,
  },
  { key: 'action', contentTemplateId: 'actions', width: 120 },
];

const PatientTransactionsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<ITransactionDao[]>([]);
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = TransactionApiService.onCollectionSnapshot(
      (snap) => {
        setTransactions(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.transactions.error'));
        sentryCaptureException(error, 'Patient file fetching transactions', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        orderBy('date', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const dateTemplate = (transaction: ITransactionDao) => {
    return <p className='body-sm'>{dayjs(transaction.date.toDate()).format('DD/MM/YYYY, HH:mm')}</p>;
  };

  const typeTemplate = (transaction: ITransactionDao) => {
    const typeData = TransactionTypeData[transaction.transactionType];
    const Icon = typeData?.icon;
    return (
      <div className='flex items-center space-x-2'>
        <div
          style={{ backgroundColor: `${typeData.color}19`, borderColor: typeData.color }}
          className='rounded-full p-1 border'
        >
          <Icon color={typeData.color} size={12} />
        </div>
        <p>{t(typeData.translationLabelKey)}</p>
      </div>
    );
  };

  const amountTemplate = (transaction: ITransactionDao) => {
    const modifier = transaction.transactionType !== 'charge' ? '-' : '+';
    return (
      <p className='body-sm'>
        {modifier} {formatToCurrency(transaction.amount, organisationData?.finance.currency)}
      </p>
    );
  };

  const methodTemplate = (transaction: ITransactionDao) => {
    const methodData = PaymentMethodData[transaction.method];
    return (
      <p className={clsx('body-sm', !methodData && 'text-gray-300')}>
        {t(methodData?.translationLabelKey ?? 'common.na')}
      </p>
    );
  };

  const createdTemplate = (transaction: ITransactionDao) => {
    return (
      <p className='body-sm'>
        {t('common.at_by', {
          at: dayjs(transaction.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: transaction.created.by.fullName,
        })}
      </p>
    );
  };

  const actionTemplate = (transaction: ITransactionDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.TRANSACTIONS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditTransactionDialog patient={patient} transaction={transaction} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.TRANSACTIONS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => TransactionApiService.permDelete(transaction.uid)}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('patients.patient.transactions.delete_transaction.title')}
                  textContent={t('patients.patient.transactions.delete_transaction.content')}
                  successMessage={t('patients.patient.transactions.delete_transaction.success')}
                  errorMessage={t('patients.patient.transactions.delete_transaction.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: dateTemplate,
      id: 'date',
    },
    {
      template: typeTemplate,
      id: 'type',
    },
    {
      template: amountTemplate,
      id: 'amount',
    },
    {
      template: methodTemplate,
      id: 'method',
    },
    {
      template: createdTemplate,
      id: 'created',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  return (
    <>
      <PatientFinancialWidget patient={patient} rowLayout />
      <SharedCard
        title={t('patients.patient.transactions.title')}
        extra={
          <SharedButton
            labelKey='patients.patient.transactions.add_transaction'
            appearance='link'
            primaryOverride
            onClick={() => dialog?.openDialog(<AddEditTransactionDialog patient={patient} />)}
          />
        }
      >
        <SharedTable
          loading={loading}
          rows={transactions.map((transaction) => ({
            key: transaction.uid,
            data: transaction,
          }))}
          columns={tableColumns}
          contentTemplates={contentTemplates}
          headerBackgroundColor='#f8fafc'
        />
      </SharedCard>
    </>
  );
};

export default PatientTransactionsList;
