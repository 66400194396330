import clsx from 'clsx';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getAppointmentStyle, getTimeStringFromTimestamp } from 'shared/helpers/appointment-helpers';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { UnavailabilityTypeData } from 'core/constants/unavailability-type';

interface ISharedCalendarDailyUnavailability {
  unavailability: IHolidayAndUnavailabilityDao;
  timeSlotHeight: number;
}

const SharedCalendarDailyUnavailability = ({ unavailability, timeSlotHeight }: ISharedCalendarDailyUnavailability) => {
  const { primary } = useTheme();
  const { t } = useTranslation();

  const { organisationData } = useUserState();

  const start = dayjs(organisationData?.calendar.startTime.toDate());
  const end = dayjs(organisationData?.calendar.endTime.toDate());

  const typeData = UnavailabilityTypeData[unavailability.type];

  return (
    <div
      className={clsx(
        'group absolute w-full rounded-md shadow-sm z-10 overflow-y-auto hide-scrollbar flex justify-between items-start'
      )}
      style={getAppointmentStyle(
        start,
        end,
        getTimeStringFromTimestamp(unavailability.startDateTime),
        getTimeStringFromTimestamp(unavailability.endDateTime),
        false,
        1,
        0,
        timeSlotHeight,
        `repeating-linear-gradient(45deg, ${primary.bg}26, ${primary.bg}26 10px, ${primary.bg}3F 10px, ${primary.bg}3F 20px)`
      )}
    >
      <div className='relative w-full'>
        <div className='p-2'>
          <p className='body-sm text-slate-500 font-semibold'>{`${t(typeData.translationLabelKey)} ${
            unavailability.description ? `(${unavailability.description})` : ''
          }`}</p>
        </div>
      </div>
    </div>
  );
};

export default SharedCalendarDailyUnavailability;
