import { IAppointmentDao } from 'core/api/types/appointment.interface';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import SharedCalendarWeeklyRow from './calendar-weekly-row';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import clsx from 'clsx';
import TimelinePreviewDialog from 'shared/dialog/timeline-preview-dialog';
import { useEffect, useState } from 'react';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { useSelector } from 'react-redux';
import { MapPin } from 'react-feather';

export interface ISharedCalendarNewAppointment {
  date?: Dayjs;
  start: string;
  end: string;
}

interface ISharedCalendar {
  timeSlots: string[];
  appointments: IAppointmentDao[];
  unavailability: IHolidayAndUnavailabilityDao[];
  people: IDomainCalendarResource[];
  currentDate: Dayjs;
  showAppointmentMenu?: boolean;
  newAppointment?: ISharedCalendarNewAppointment;
  zoom: number;
}

const SharedCalendarWeekly = ({
  timeSlots,
  appointments,
  unavailability,
  people,
  currentDate,
  showAppointmentMenu,
  newAppointment,
  zoom,
}: ISharedCalendar) => {
  const dialog = useDialog();
  const { primary } = useTheme();
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const { t } = useTranslation();

  const [daysOfWeek, setDaysOfWeek] = useState([] as Dayjs[]);
  const columnWidth = 350 * zoom;
  const minimumHeight = 64;

  useEffect(() => {
    const weekDates = [];

    for (let i = 0; i < 7; i++) {
      weekDates.push(currentDate.startOf('week').add(i, 'day'));
    }

    setDaysOfWeek(weekDates);
  }, [currentDate]);

  const getWorkingLocation = (resource: IDomainCalendarResource) => {
    const day = currentDate.day();
    const todaysLocation = resource.workingLocations?.[day];

    let label: string | undefined;

    switch (todaysLocation) {
      case 'domiciliary':
        label = t('working_location.domiciliary');
        break;
      case 'nonWorking':
        label = t('working_location.non_working');
        break;
      default: {
        const matchingClinic = clinicsState?.data.find((clinic) => clinic.uid === todaysLocation);
        if (matchingClinic) {
          label = matchingClinic.name;
        }
      }
    }

    if (!label) {
      return <></>;
    }

    return (
      <div className='body-xs text-gray-400 flex space-x-1 items-center justify-center'>
        <MapPin size={12} />
        <p>{label}</p>
      </div>
    );
  };

  return (
    <div className='w-full flex grow overflow-y-auto'>
      <div className={`flex flex-col grow sticky left-0 z-30`}>
        <div
          className={
            'w-full min-h-[50px] border border-t-0 border-l-0 bg-gray-50 p-4 text-center font-semibold z-30 transition ease-in-out'
          }
        />
        {people.map((person) => (
          <Tooltip
            key={person.uid}
            title={showAppointmentMenu ? t('calendar.calendar_column.resource_header.view_todays_route') : undefined}
            color={primary.bg}
            arrow={false}
          >
            <button
              className={clsx(
                `grow w-full h-full flex flex-col items-center justify-center border border-t-0 last:border-b-0 border-l-0 bg-gray-50 p-4 text-center z-30 transition ease-in-out`,
                showAppointmentMenu && 'cursor-pointer hover:bg-gray-100'
              )}
              style={{ minHeight: `${minimumHeight}px` }}
              onClick={() => {
                if (!showAppointmentMenu) {
                  return;
                }

                dialog?.openDialog(
                  <TimelinePreviewDialog
                    appointments={appointments.filter((app) => app.assignee.uid === person.uid)}
                    resource={person}
                  />
                );
              }}
            >
              <p className='font-semibold'>{person.fullName}</p>
              {getWorkingLocation(person)}
            </button>
          </Tooltip>
        ))}
      </div>
      <div className='w-full flex flex-col grow'>
        <div className='sticky flex top-0 z-40 bg-white'>
          {daysOfWeek.map((day) => (
            <div
              className={`w-full flex items-center justify-center border-r last:border-r-0 border-b bg-white`}
              style={{ minWidth: `${columnWidth}px`, height: '50px' }}
              key={day.format('YYYY-MM-DD')}
            >
              {day.format('dddd, D MMMM')}
            </div>
          ))}
        </div>
        {people.map((person) => (
          <SharedCalendarWeeklyRow
            key={person.uid}
            existingAppointments={appointments.filter((app) => app.assignee.uid === person.uid)}
            unavailability={unavailability.filter((u) => u.assignee.uid === person.uid)}
            daysOfWeek={daysOfWeek}
            timeSlots={timeSlots}
            minimumHeight={minimumHeight}
            newAppointment={newAppointment}
            width={columnWidth}
          />
        ))}
      </div>
    </div>
  );
};

export default SharedCalendarWeekly;
