import SharedDialogBase from 'shared/dialog/dialog-base';

const NoahSyncActionCreatedSuccessfullyDialog = () => {
  return (
    <SharedDialogBase
      title={'Launching Noah...'}
      textContent={'A message has been transferred to the HearLink Noah sync app, Noah should be opened shortly'}
    />
  );
};

export default NoahSyncActionCreatedSuccessfullyDialog;
