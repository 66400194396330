import { IPatientDao } from 'core/api/types';
import PatientOverviewAppointmentsWidget from './patient-overview-appointments-widget';
import PatientFinancialWidget from '../patient-financials-widget';
import PatientOverviewLastTestWidget from './patient-overview-last-test-widget';
import PatientOverviewManufacturerWidget from './patient-overview-manufacturer-widget';
import PatientOverviewLastCommunicationWidget from './patient-overview-last-communication-widget';
import PatientOverviewLastTransactionsWidget from './patient-overview-last-transactions-widget';

const PatientOverview = (patient: IPatientDao) => {
  return (
    <div className='md:grid md:grid-cols-4 gap-4 md:gap-y-0 grid-rows-4'>
      <PatientOverviewAppointmentsWidget {...patient} />
      <PatientFinancialWidget patient={patient} />
      <PatientOverviewLastTestWidget {...patient} />
      <PatientOverviewManufacturerWidget {...patient} />
      <PatientOverviewLastCommunicationWidget {...patient} />
      <PatientOverviewLastTransactionsWidget {...patient} />
    </div>
  );
};

export default PatientOverview;
