import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { Drawer, Select } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { Filter } from 'react-feather';
import { IFilter } from './filter';

interface IDrawerFilter {
  filters: IFilter[];
  onFilterChange: (filters: { [key: string]: string[] }) => void;
}

const DrawerFilter = ({ filters, onFilterChange }: IDrawerFilter) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);

  const updatedFilters = useMemo(() => {
    return Array.from(searchParams.entries()).reduce((acc, [k, v]) => {
      acc[k] = v.split(',');
      return acc;
    }, {} as { [key: string]: string[] });
  }, [searchParams]);

  useEffect(() => {
    onFilterChange(updatedFilters);
  }, [updatedFilters, onFilterChange]);

  const handleFilterChange = (key: string, value: string[]) => {
    setSearchParams((prev) => {
      if (value.length === 0) {
        prev.delete(key);
      } else {
        prev.set(key, value.join(','));
      }

      return prev;
    });
  };

  const clearFilters = () => {
    setSearchParams((prev) => {
      filters.forEach((filter) => {
        prev.delete(filter.key);
      });
      return prev;
    });
  };

  return (
    <>
      <SharedButton appearance='default' icon={<Filter size={20} />} onClick={() => setShowFilters(true)} />
      <Drawer
        title={t('filters.title')}
        extra={<SharedButton onClick={clearFilters} labelKey='filters.clear' primaryOverride appearance='link' />}
        onClose={() => setShowFilters(false)}
        open={showFilters}
      >
        <div className='space-y-4'>
          {filters.map((filter) => (
            <Select
              maxCount={filter.maxCount}
              value={searchParams.get(filter.key)?.split(',')}
              key={filter.key}
              placeholder={filter.label}
              options={filter.options}
              mode={filter.mode}
              style={{ width: '100%' }}
              onChange={(value) => handleFilterChange(filter.key, value)}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
