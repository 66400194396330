import { ICustomFormTemplateComponent } from 'core/api/types/custom-form-templates.interface';
import { useTheme } from 'core/providers/theme-provider';
import { useTranslation } from 'react-i18next';
import SharedCustomForm from 'shared/custom-form/custom-form';
import SharedDialogBase from 'shared/dialog/dialog-base';

interface IAddEditCustomFormTemplatePreviewDialog {
  name: string;
  components: ICustomFormTemplateComponent[];
}

const AddEditCustomFormTemplatePreviewDialog = ({ name, components }: IAddEditCustomFormTemplatePreviewDialog) => {
  const { t } = useTranslation();
  const { primary } = useTheme();

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <p className='header-lg mb-6' style={{ color: primary.bg }}>
          {name}
        </p>
        <SharedCustomForm onFinish={() => {}} name={name ?? 'public-form'} components={components ?? []} previewMode />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('form_settings.custom_forms.add_edit_form.preview')}
      customContentTemplate={customContent()}
    />
  );
};

export default AddEditCustomFormTemplatePreviewDialog;
