import { Routes, Route, Navigate } from 'react-router-dom';
import PublicForm from './public-form';

const PublicFormsRouting = () => {
  return (
    <Routes>
      <Route path=':uid' element={<PublicForm />} />
      <Route path='*' element={<Navigate replace to='/404' />} />
    </Routes>
  );
};

export default PublicFormsRouting;
