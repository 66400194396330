import { ISharedButton } from './button.interface';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useTheme } from 'core/providers/theme-provider';

const SharedButton = ({
  labelKey,
  onClick,
  type,
  loading = false,
  disabled = false,
  appearance,
  danger = false,
  ghost = false,
  size,
  primaryOverride,
  height,
  className,
  fullWidth = false,
  icon,
  shape = 'default',
  additionalStyle,
  href,
}: ISharedButton) => {
  const { t } = useTranslation();
  const { primary } = useTheme();

  return (
    <Button
      shape={shape}
      icon={icon}
      className={className}
      style={{
        color: primaryOverride ? primary.bg : undefined,
        height: height ? `${height}px` : undefined,
        padding: appearance === 'link' ? '0' : '4px 15px',
        ...additionalStyle,
      }}
      type={appearance}
      danger={danger}
      ghost={ghost}
      size={size}
      htmlType={type}
      disabled={disabled}
      loading={loading}
      href={href}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      block={fullWidth}
    >
      {labelKey && t(labelKey)}
    </Button>
  );
};

export default SharedButton;
