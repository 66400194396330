import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import {
  IGetAddressAddress,
  IGetAddressSuggestions,
  IGoogleAutocompleteSuggestions,
  IGoogleMapsAddress,
} from './types/address.interface';

const API_KEY = process.env.REACT_APP_GET_ADDRESS_KEY;

const autocompleteUK = async (term: string): Promise<IGetAddressSuggestions> => {
  try {
    const response = await fetch(`https://api.getAddress.io/autocomplete/${term}?api-key=${API_KEY}&all=true&top=20`);
    const json = await response.json();
    return json as IGetAddressSuggestions;
  } catch (error) {
    sentryCaptureException(error, 'GetAddress autocomplete fetch');
    throw error;
  }
};

const getFullAddressUK = async (id: string): Promise<IGetAddressAddress> => {
  try {
    const response = await fetch(`https://api.getAddress.io//get/${id}?api-key=${API_KEY}`);
    const json = await response.json();
    return json as IGetAddressAddress;
  } catch (error) {
    sentryCaptureException(error, 'GetAddress full address fetch');
    throw error;
  }
};

const autocompleteGoogle = async (term: string, countryCode: string): Promise<IGoogleAutocompleteSuggestions> => {
  try {
    const token = new google.maps.places.AutocompleteSessionToken();
    const { fetchAutocompleteSuggestions } = google.maps.places.AutocompleteSuggestion;
    const response = await fetchAutocompleteSuggestions({
      input: term,
      sessionToken: token,
      includedRegionCodes: [countryCode],
    });
    return {
      suggestions: response.suggestions.map(({ placePrediction }) => ({
        address: placePrediction?.text.toString(),
        placePredication: placePrediction,
        id: placePrediction?.placeId,
      })),
    };
  } catch (error) {
    sentryCaptureException(error, 'GetAddress autocomplete fetch');
    throw error;
  }
};

const getFullAddressGoogle = async (
  placePrediction: google.maps.places.PlacePrediction | null
): Promise<IGoogleMapsAddress> => {
  if (!placePrediction) {
    throw new Error('No place prediction');
  }
  try {
    const place = placePrediction.toPlace();
    await place.fetchFields({
      fields: ['formattedAddress', 'addressComponents', 'location'],
    });
    const postcode =
      place.addressComponents?.find((component) => component.types.includes('postal_code'))?.longText ?? '';
    if (!postcode || !place.location || !place.formattedAddress) {
      throw new Error('No postcode, formatted address or location');
    }
    return {
      postcode,
      latitude: place.location.lat(),
      longitude: place.location.lng(),
      formattedAddress: place.formattedAddress,
    };
  } catch (error) {
    sentryCaptureException(error, 'Google full address fetch');
    throw error;
  }
};

const AddressApiService = {
  autocompleteUK,
  getFullAddressUK,
  autocompleteGoogle,
  getFullAddressGoogle,
};

export default AddressApiService;
