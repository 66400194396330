import { App } from 'antd';
import { OrganisationsApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedPageHeader from 'shared/page-header/page-header';

interface IBookingWidgetSettingsFormOutput {
  enabledDays: number[];
}

const BookingWidgetSettings = () => {
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const [updating, setUpdating] = useState(false);

  const onValueChange = async (data: IBookingWidgetSettingsFormOutput) => {
    try {
      setUpdating(true);
      await OrganisationsApiService.update(organisationData?.uid!, {
        bookingWidgetSettings: data,
        updated: getActionTimestampFromUser(userData),
      });
      message.success(t('booking_widget_settings.update.success'));
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      message.error(t('booking_widget_settings.update.error'));
      sentryCaptureException(error, 'booking-widget-settings-update', userData);
    }
  };

  const fields: ISharedField[] = [
    {
      control: ControlType.CheckboxGroup,
      options: [1, 2, 3, 4, 5, 6, 0].map((day) => ({
        label: t(`day.${day}`),
        value: day,
      })),
      fieldKey: 'enabledDays',
      label: t(`booking_widget_settings.form.enabled_days`),
      required: true,
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.booking_widget_settings')} />
      <SharedCard>
        <SharedForm<IBookingWidgetSettingsFormOutput>
          name='booking-widget-settings'
          submitting={updating}
          fields={fields}
          buttonsOverride={[]}
          onChange={(_, all) => onValueChange(all)}
          existingValue={{
            enabledDays: organisationData?.bookingWidgetSettings?.enabledDays ?? [1, 2, 3, 4, 5],
          }}
        />
      </SharedCard>
    </>
  );
};

export default BookingWidgetSettings;
