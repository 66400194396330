import { ICustomFormDao } from 'core/api/types/custom-form.interface';
import { CustomFormTemplateComponentType } from 'core/constants/custom-form-component-type';
import { ControlType } from 'core/enums/control-type';
import { useTheme } from 'core/providers/theme-provider';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useSelector } from 'react-redux';
import SharedCustomForm from 'shared/custom-form/custom-form';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { StoreState } from 'store';

interface IViewCompletedPatientFormDialog {
  form: ICustomFormDao;
}

const ViewCompletedPatientFormDialog = ({ form }: IViewCompletedPatientFormDialog) => {
  const { primary } = useTheme();
  const customFormTemplate = useSelector((state: StoreState) =>
    OrganisationSettingsSlice.selectCustomFormTemplate(state, form.templateUid)
  );

  const processedFormValues = customFormTemplate?.components.reduce((acc: Record<string, any>, component) => {
    if (component.type === CustomFormTemplateComponentType.RICH_TEXT) return acc;
    const value = form.formValues?.[component.key];
    if (!value) return acc;

    if (component.control === ControlType.DatePicker) {
      acc[component.key] = dayjs(value.toDate());
    } else {
      acc[component.key] = value;
    }
    return acc;
  }, {});

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <p className='header-lg mb-6' style={{ color: primary.bg }}>
          {customFormTemplate?.name}
        </p>
        <SharedCustomForm
          onFinish={() => {}}
          name={customFormTemplate?.name ?? 'public-form'}
          components={customFormTemplate?.components ?? []}
          previewMode
          existingData={processedFormValues}
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={`${customFormTemplate?.name} - ${dayjs(form.updated.at.toDate()).format('DD/MM/YYYY, HH:mm')}`}
      customContentTemplate={customContent()}
    />
  );
};

export default ViewCompletedPatientFormDialog;
