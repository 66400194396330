import { ControlType, ControlTypeData } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';

import { useForm, useWatch } from 'antd/es/form/Form';
import { v4 as uuidv4 } from 'uuid';
import { InputType } from 'core/enums/input-type';
import { ICustomFormTemplateComponent } from 'core/api/types/custom-form-templates.interface';
import {
  CustomFormTemplateComponentType,
  CustomFormTemplateComponentTypeData,
  CustomFormTemplateComponentTypeOptions,
} from 'core/constants/custom-form-component-type';

interface IAddEditCustomFormTemplateComponentFormOutput {
  type: CustomFormTemplateComponentType;
  content?: string;
  label?: string;
  controlType?: ControlType;
  required?: boolean;
  options?: string[];
}

interface IAddEditCustomFormTemplateComponent {
  component?: ICustomFormTemplateComponent;
  setComponents: Dispatch<SetStateAction<ICustomFormTemplateComponent[]>>;
}

const AddEditCustomFormTemplateComponent = ({ component, setComponents }: IAddEditCustomFormTemplateComponent) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const [form] = useForm();
  const type = useWatch('type', form);
  const control = useWatch('controlType', form);

  const controlTypeOptions = [
    ControlType.TextField,
    ControlType.NumberField,
    ControlType.Select,
    ControlType.CheckboxGroup,
    ControlType.DatePicker,
    ControlType.TextArea,
    ControlType.Signature,
  ];

  const fields: ISharedField[] = [
    {
      fieldKey: 'type',
      control: ControlType.Select,
      options: CustomFormTemplateComponentTypeOptions.map((type) => {
        const data = CustomFormTemplateComponentTypeData[type];
        return { label: t(data.translationLabelKey), value: data.value };
      }),
      label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.type'),
      required: true,
    },
    {
      fieldKey: 'content',
      control: ControlType.RichText,
      label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.content'),
      required: true,
      hidden: type !== CustomFormTemplateComponentType.RICH_TEXT,
    },
    {
      fieldKey: 'label',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.label'),
      required: true,
      hidden: type !== CustomFormTemplateComponentType.FIELD,
    },
    {
      fieldKey: 'controlType',
      control: ControlType.Select,
      options: controlTypeOptions.map((type) => {
        const data = ControlTypeData[type];
        return { label: t(data.translationLabelKey), value: data.value };
      }),
      label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.control_type'),
      required: true,
      hidden: type !== CustomFormTemplateComponentType.FIELD,
    },
    {
      fieldKey: 'options',
      control: ControlType.SelectMultipleTags,
      label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.options'),
      required: true,
      hidden:
        type !== CustomFormTemplateComponentType.FIELD ||
        ![ControlType.Select, ControlType.CheckboxGroup].includes(control),
    },
    {
      fieldKey: 'required',
      control: ControlType.Switch,
      label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.required'),
      required: true,
      hidden: type !== CustomFormTemplateComponentType.FIELD,
    },
  ];

  const submit = async (data: IAddEditCustomFormTemplateComponentFormOutput) => {
    const basePayload =
      type === CustomFormTemplateComponentType.RICH_TEXT
        ? { type, content: data.content!, key: uuidv4() }
        : {
            key: uuidv4(),
            type,
            control: data.controlType!,
            label: data.label!,
            required: data.required!,
            ...(data.options && { options: data.options }),
          };
    if (component) {
      setComponents((prev) =>
        prev.map((c) => {
          if (c.key === component.key) {
            return { ...basePayload, order: c.order };
          }
          return c;
        })
      );
      dialog?.closeDialog();
    } else {
      setComponents((prev) => [...prev, { ...basePayload, order: prev.length }]);
      dialog?.closeDialog();
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditCustomFormTemplateComponentFormOutput>
        formInstance={form}
        onFinish={submit}
        fields={fields}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-component-form'
        existingValue={
          component
            ? {
                type: component.type,
                ...(component.type === CustomFormTemplateComponentType.RICH_TEXT && { content: component.content }),
                ...(component.type === CustomFormTemplateComponentType.FIELD && {
                  label: component.label,
                  controlType: component.control,
                  required: component.required,
                }),
              }
            : {
                required: false,
              }
        }
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        component
          ? 'form_settings.custom_forms.add_edit_form.components.add_edit_component.edit.title'
          : 'form_settings.custom_forms.add_edit_form.components.add_edit_component.add.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditCustomFormTemplateComponent;
