import clsx from 'clsx';

interface IFormItemMimic {
  label: string;
  children: any;
  className?: string;
  labelClassName?: string;
}

const FormItemMimic = ({ label, children, className = 'mb-4', labelClassName = '' }: IFormItemMimic) => {
  return (
    <div className={className}>
      <label
        className={clsx('relative text-[14px] inline-flex max-w-full pb-[2px]', labelClassName)}
        style={{ color: 'rgba(0, 0, 0, 0.88)' }}
      >
        {label}
      </label>
      <div>{children}</div>
    </div>
  );
};

export default FormItemMimic;
