import { Select } from 'antd';
import { IUserWorkingLocationsDao } from 'core/api/types/users.interface';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FormItemMimic from 'shared/form/form-item-mimic';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';

interface IUserWorkingLocations {
  workingLocations?: IUserWorkingLocationsDao;
  update: Dispatch<SetStateAction<IUserWorkingLocationsDao | undefined>>;
}

const UserWorkingLocations = ({ workingLocations, update }: IUserWorkingLocations) => {
  const { t } = useTranslation();
  const dayIndexes = [1, 2, 3, 4, 5, 6, 0];
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const selectOptions = [
    ...(clinicsState?.data.map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? []),
    {
      label: t('working_location.domiciliary'),
      value: 'domiciliary',
    },
    {
      label: t('working_location.non_working'),
      value: 'nonWorking',
    },
  ];

  const handleSelection = (dayIndex: number, value: string) => {
    update((prev) => ({ ...prev, [dayIndex]: value }));
  };

  return (
    <FormItemMimic label={t('dialog.add_edit_user.form.working_location')}>
      {dayIndexes.map((dayIndex) => (
        <div key={dayIndex} className='grid grid-cols-6 gap-4 mt-4'>
          <p>{t(`day.${dayIndex}`)}</p>
          <Select
            className='col-span-5'
            options={selectOptions}
            onChange={(val) => handleSelection(dayIndex, val)}
            value={workingLocations?.[dayIndex]}
          />
        </div>
      ))}
    </FormItemMimic>
  );
};

export default UserWorkingLocations;
