import { Navigate, Route, Routes } from 'react-router-dom';
import EditUploadSettings from './edit-upload-settings';
import DocumentsAndImagesSettingsList from './documents-and-images-settings-list';
import FittingReceiptSettings from './fitting-receipt-settings';

const FinanceRouting = () => {
  return (
    <Routes>
      <Route path='' element={<DocumentsAndImagesSettingsList />} />
      <Route path='upload-settings' element={<EditUploadSettings />} />
      <Route path='fitting-receipt-settings' element={<FittingReceiptSettings />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default FinanceRouting;
