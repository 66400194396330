import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useUserState } from './user-provider';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IUserDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';

interface IPermissionsContext {
  userPermissions?: string[];
  isSuperAdmin: boolean;
}

export const PermissionsContext = createContext<IPermissionsContext>({
  isSuperAdmin: false,
});

export const usePermissionsState = () => {
  const state = useContext(PermissionsContext);
  return state;
};

export const PermissionsProvider = ({ children }: any) => {
  const { userData } = useUserState();
  const [userPermissions, setUserPermissions] = useState<string[]>();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const processUserPermissions = useCallback(
    async (userData: IUserDao) => {
      try {
        setUserPermissions(userData.permissions);
        setIsSuperAdmin(userData.permissions.includes(Permission.HEARLINK_ADMIN));
      } catch (error) {
        message.error(t('misc.permissions.error'));
        sentryCaptureException(error, 'Processing user permissions');
      }
    },
    [message, t]
  );

  useEffect(() => {
    if (!userData) {
      setIsSuperAdmin(false);
      setUserPermissions(undefined);
    } else {
      processUserPermissions(userData);
    }
  }, [userData, processUserPermissions]);

  return (
    <PermissionsContext.Provider value={{ userPermissions, isSuperAdmin }}>{children}</PermissionsContext.Provider>
  );
};
