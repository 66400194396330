import { App } from 'antd';
import clsx from 'clsx';
import { StockApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { and, or, orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import { IStockDao } from 'core/api/types/stock.interface';
import SharedStockStatusTableTemplate from 'shared/stock-management/stock-status-table-template';
import dayjs from 'dayjs';
import { Ear, EarData } from 'core/constants/ear';
import { EarIcon } from 'lucide-react';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import EditAllocationDialog from './edit-allocation-dialog';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'stock_management.overview.table.header.item',
    key: 'item',
    contentTemplateId: 'item',
    width: 500,
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.serial_number',
    key: 'serialNumber',
    contentTemplateId: 'serialNumber',
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.warranty',
    key: 'warranty',
    contentTemplateId: 'warranty',
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.status',
    key: 'status',
    contentTemplateId: 'status',
  },
  {
    labelKey: 'stock_management.overview.table.header.fitted_date',
    key: 'fittedDate',
    contentTemplateId: 'fittedDate',
  },
  {
    key: 'actions',
    contentTemplateId: 'actions',
    width: 40,
  },
];

const PatientProductsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [stockList, setStockList] = useState<IStockDao[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        const allStock: IStockDao[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          if (data.type === 'hearingAid') {
            allStock.push(data);
          } else {
            const allStockAssignments = data.allocation.allocations.filter((a) => a.to.uid === patient.uid);
            allStockAssignments.forEach((assignment) => {
              allStock.push({
                ...data,
                allocation: {
                  ...data.allocation,
                  allocations: [assignment],
                },
              });
            });
          }
        });
        setStockList(
          allStock.sort((a, b) => {
            const allocationDate = (stock: IStockDao) => {
              if (stock.type === 'hearingAid') {
                return stock.allocated?.at.seconds ?? 0;
              } else {
                return stock.allocation.allocations[0]?.at.seconds ?? 0;
              }
            };
            return allocationDate(b) - allocationDate(a);
          })
        );
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.products.error'));
        sentryCaptureException(error, 'Patient file fetching transactions', userData);
      },
      [],
      {
        filter: and(
          where('organisationUid', '==', userData?.organisationUid),
          or(
            and(where('type', '==', 'hearingAid'), where('allocated.to.uid', '==', patient.uid)),
            and(where('type', '==', 'accessory'), where('allocation.patients', 'array-contains', patient.uid))
          )
        ),
        orderBy: orderBy('updated.at', 'desc'),
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const itemTemplate = (stock: IStockDao) => {
    let values = [];
    let side:
      | {
          translationLabelKey: string;
          value: string;
        }
      | undefined;
    switch (stock.type) {
      case 'hearingAid':
        values = [stock.manufacturer, stock.model, stock.style, stock.power, stock.colour];
        side = stock.allocated?.side ? EarData[stock.allocated?.side] : undefined;
        break;
      case 'accessory':
        values = [stock.manufacturer, stock.accessoryName];
        break;
    }

    return (
      <div className='flex items-center space-x-3'>
        <p>{values.filter((value) => Boolean(value)).join(' / ')}</p>
        {side && (
          <div
            className={clsx(
              side.value === Ear.LEFT
                ? 'bg-blue-50 border-blue-300 text-blue-500'
                : 'bg-red-50 border-red-300 text-red-500',
              'p-1 rounded-md border body-xs flex items-center space-x-1'
            )}
          >
            <EarIcon
              size={14}
              style={
                side.value === Ear.LEFT
                  ? {
                      transform: 'scaleX(-1)',
                      WebkitTransform: 'scaleX(-1)',
                      MozTransform: 'scaleX(-1)',
                      OTransform: 'scaleX(-1)',
                      msTransform: 'scaleX(-1)',
                    }
                  : undefined
              }
            />
            <p>{t(side.translationLabelKey)}</p>
          </div>
        )}
      </div>
    );
  };

  const serialTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('text-sm', !stock.serialNumber && 'text-gray-400')}>
        {stock.serialNumber ?? t('common.not_provided')}
      </p>
    );
  };

  const warrantyTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('text-sm', !stock.warranty && 'text-gray-400')}>
        {stock.warranty ?? t('common.not_provided')}
      </p>
    );
  };

  const statusTemplate = (stock: IStockDao) => {
    const multiAccessoryAllocation =
      stock.type === 'accessory' && stockList.filter((s) => s.uid === stock.uid).length > 1;
    return (
      <SharedStockStatusTableTemplate
        stock={stock}
        patientUid={patient.uid}
        multiAccessoryAllocation={multiAccessoryAllocation}
      />
    );
  };

  const fittedDateTemplate = (stock: IStockDao) => {
    let allocatedDate: Date | undefined;
    if (stock.type === 'hearingAid') {
      allocatedDate = stock.allocated?.at.toDate();
    } else if (stock.type === 'accessory') {
      const allocation = stock.allocation.allocations.find((a) => a.to.uid === patient.uid);
      allocatedDate = allocation?.at.toDate();
    }
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {allocatedDate ? dayjs(allocatedDate).format('DD/MM/YYYY') : t('common.unknown')}
      </p>
    );
  };

  const actionsTemplate = (stock: IStockDao) => {
    const multiAccessoryAllocation =
      stock.type === 'accessory' && stockList.filter((s) => s.uid === stock.uid).length > 1;
    return (
      <div className='w-full flex justify-end'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <EditAllocationDialog
                  patient={patient}
                  stockItem={stock}
                  multiAccessoryAllocation={multiAccessoryAllocation}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  return (
    <SharedCard title={t('patients.patient.products.title')}>
      <SharedTable
        loading={loading}
        rows={stockList.map((item, index) => ({
          key: `${item.uid}-${index}`,
          data: item,
        }))}
        columns={tableColumns}
        contentTemplates={[
          {
            id: 'item',
            template: itemTemplate,
          },
          {
            template: serialTemplate,
            id: 'serialNumber',
          },
          {
            template: statusTemplate,
            id: 'status',
          },
          {
            template: fittedDateTemplate,
            id: 'fittedDate',
          },
          {
            template: warrantyTemplate,
            id: 'warranty',
          },
          {
            template: actionsTemplate,
            id: 'actions',
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientProductsList;
