import { Navigate, Route, Routes } from 'react-router-dom';
import FormSettingsList from './form-settings-list';
import EditPatientFormSettings from './edit-patient-form-settings';
import EditOrderFormSettings from './edit-order-form-settings';
import CustomFormsList from './custom-form-templates/custom-form-templates-list';
import AddEditCustomFormTemplate from './custom-form-templates/add-edit-custom-form-template';

const FormSettingsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<FormSettingsList />} />
      <Route path='patient-form' element={<EditPatientFormSettings />} />
      <Route path='order-form' element={<EditOrderFormSettings />} />
      <Route path='custom-forms' element={<CustomFormsList />} />
      <Route path='custom-forms/create' element={<AddEditCustomFormTemplate />} />
      <Route path='custom-forms/edit/:uid' element={<AddEditCustomFormTemplate />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default FormSettingsRouting;
