import { UsersApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import NewUserPasswordDialog from 'shared/dialog/new-user-password-dialog';
import { useUserState } from 'core/providers/user-provider';
import { IUserDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { useTable } from 'core/providers/table-data-provider';
import { App } from 'antd';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { useSelector } from 'react-redux';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { IUserWorkingLocationsDao } from 'core/api/types/users.interface';
import UserWorkingLocations from './user-working-locations';

const emailFieldKey = 'emailAddress';
const fullNameFieldKey = 'fullName';
const clinicsFieldKey = 'clinics';
const homeAddressFieldKey = 'homeAddress';
interface IAddEditUserFormOutput {
  [emailFieldKey]: string;
  [fullNameFieldKey]: string;
  [clinicsFieldKey]?: string[];
  [homeAddressFieldKey]: IAddressDao;
}

interface IAddEditUserDialog {
  uid?: string;
  tableKey: string;
}

const AddEditUserDialog = ({ uid, tableKey }: IAddEditUserDialog) => {
  const [fetchingData, setFetchingData] = useState(false);
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const creating = !uid;
  const dialog = useDialog();
  const { message } = App.useApp();
  const [existingData, setExistingData] = useState<IUserDao>();
  const clinics = useSelector(OrganisationSettingsSlice.selectClinics);
  const [workingLocations, setWorkingLocations] = useState<IUserWorkingLocationsDao>();

  const AdminAddEditUserFormFields: ISharedField[] = [
    {
      fieldKey: fullNameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.full_name'),
      required: true,
    },
    {
      fieldKey: emailFieldKey,
      control: ControlType.TextField,
      type: InputType.Email,
      label: t('common.email_address'),
      required: true,
    },
    {
      fieldKey: clinicsFieldKey,
      control: ControlType.Select,
      label: t('navigation.clinics'),
      options: clinics?.data?.map((clinic) => ({ label: clinic.name, value: clinic.uid })) || [],
      required: false,
      mode: 'multiple',
    },
    {
      fieldKey: homeAddressFieldKey,
      control: ControlType.Address,
      label: t('dialog.add_edit_user.form.home_address'),
      required: false,
      extra: <UserWorkingLocations workingLocations={workingLocations} update={setWorkingLocations} />,
    },
  ];
  const table = useTable(tableKey);

  useEffect(() => {
    if (!creating) {
      const prepare = async () => {
        setFetchingData(true);
        const snap = await UsersApiService.get(uid);
        const data = snap.data();
        setWorkingLocations(data?.workingLocations);
        setExistingData(snap.data());
        setFetchingData(false);
      };
      prepare();
    }
  }, [creating, uid]);

  const submit = async (data: IAddEditUserFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      if (creating) {
        const temporaryPassword = await UsersApiService.createOrganisationMember({
          ...data,
          workingLocations,
          organisationUid: userData.organisationUid,
        });
        dialog?.replaceDialog(
          <NewUserPasswordDialog
            fullName={data.fullName}
            emailAddress={data.emailAddress}
            temporaryPassword={temporaryPassword}
          />
        );
      } else {
        const { homeAddress, ...rest } = data;
        await UsersApiService.update(uid, {
          ...rest,
          ...(homeAddress && { homeAddress }),
          ...(workingLocations && { workingLocations }),
          updated: getActionTimestampFromUser(userData),
        });
        dialog?.closeDialog();
        message.success(t('dialog.add_edit_user.edit.success.description'));
      }
      table?.refreshTable();
    } catch (error) {
      message.error(
        t(creating ? 'dialog.add_edit_user.create.error.description' : 'dialog.add_edit_user.edit.error.description')
      );
      setSubmitting(false);
      sentryCaptureException(error, creating ? 'Create user' : 'Edit user', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditUserFormOutput>
        initializing={fetchingData}
        onFinish={submit}
        fields={AdminAddEditUserFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        existingValue={
          existingData && {
            [fullNameFieldKey]: existingData.fullName,
            [emailFieldKey]: existingData.emailAddress,
            [clinicsFieldKey]: existingData.clinics,
            [homeAddressFieldKey]: existingData.homeAddress,
          }
        }
        name='add-edit-user-form'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_user.create.title' : 'dialog.add_edit_user.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditUserDialog;
