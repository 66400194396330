import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { AppointmentLocationData } from 'core/constants/appointment-location';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useSelector } from 'react-redux';
import BookedBy from 'shared/data-display/booked-by';
import AppointmentMenu from './appointment-menu';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface IAppointmentListItem {
  appointment: IAppointmentDao;
  showMenu?: boolean;
  onClick?: () => void;
  refreshAppointments?: () => void;
}

const AppointmentListItem = ({ appointment, showMenu = true, onClick, refreshAppointments }: IAppointmentListItem) => {
  const appointmentTypes = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const clinics = useSelector(OrganisationSettingsSlice.selectClinics);
  const { t } = useTranslation();

  const getAppointmentType = (type: string) => {
    const appointmentType = appointmentTypes?.data?.find((appointmentType) => appointmentType.uid === type);
    return appointmentType;
  };

  const getClinic = (clinicUid: string) => {
    const clinic = clinics?.data?.find((clinic) => clinic.uid === clinicUid);
    return clinic!.name;
  };
  return (
    <div
      className={clsx(
        'relative border-b last:border-b-0 p-4 pl-6 flex items-start justify-between',
        onClick && 'cursor-pointer hover:bg-gray-50',
        appointment.cancelled && 'line-through'
      )}
      {...{ onClick }}
    >
      <div>
        <span
          className='absolute left-0 top-0 bottom-0 w-2'
          style={{
            backgroundColor: appointment.cancelled ? '#b91c1c' : getAppointmentType(appointment.type)?.colour,
          }}
        ></span>
        <p className='header-sm text-gray-800 mb-2'>{getAppointmentType(appointment.type)?.name}</p>
        <p className='body-sm text-gray-800'>
          {appointment.assignee.fullName} -{' '}
          {t(
            appointment.location ? AppointmentLocationData[appointment.location].translationLabelKey : 'common.unknown'
          )}
        </p>
        <p className='body-sm text-gray-800'>{getClinic(appointment.clinic)}</p>
        <p className='body-sm text-gray-800'>
          {dayjs(appointment.startDateTime.toDate()).format('DD/MM/YYYY [at] HH:mm')}
        </p>
        <BookedBy created={appointment.created} outerClassName='mt-4' />
      </div>
      {showMenu && <AppointmentMenu appointment={appointment} mode='list' refreshAfterAction={refreshAppointments} />}
    </div>
  );
};

export default AppointmentListItem;
