import { Form } from 'antd';
import {
  ICustomFormTemplateComponent,
  ICustomFormTemplateFieldComponent,
} from 'core/api/types/custom-form-templates.interface';
import { CustomFormTemplateComponentType } from 'core/constants/custom-form-component-type';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import SharedButton from 'shared/button/button';
import { ISharedButton } from 'shared/button/button.interface';
import { getField } from 'shared/fields/fields.helpers';
import { ISharedField } from 'shared/fields/shared-fields.interface';

interface ISharedCustomForm<T> {
  onFinish: (values: T) => void;
  name: string;
  className?: string;
  submitting?: boolean;
  components: ICustomFormTemplateComponent[];
  previewMode?: boolean;
  existingData?: Record<string, any>;
  cancelButton?: ISharedButton;
}

const SharedCustomForm = <T,>({
  onFinish,
  name,
  className,
  submitting,
  components,
  previewMode,
  existingData,
  cancelButton,
}: ISharedCustomForm<T>) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const renderFormField = (component: ICustomFormTemplateFieldComponent) => {
    let field: ISharedField | undefined;

    const { key, label, required, control, options } = component;
    switch (control) {
      case ControlType.TextField:
        field = {
          fieldKey: key,
          control: control,
          type: InputType.Text,
          label: label,
          required: required,
        };
        break;
      case ControlType.NumberField:
      case ControlType.DatePicker:
        field = {
          fieldKey: key,
          control: control,
          label: label,
          required: required,
          fullWidth: true,
        };
        break;
      case ControlType.Select:
      case ControlType.CheckboxGroup:
        field = {
          fieldKey: key,
          control: control,
          label: label,
          required: required,
          options: options?.map((option) => ({ label: option, value: option })) ?? [],
        };
        break;
      case ControlType.TextArea:
        field = {
          fieldKey: key,
          rows: 5,
          control: control,
          label: label,
          required: required,
        };
        break;
      case ControlType.Signature:
        field = {
          fieldKey: key,
          control: control,
          label: label,
          required: required,
          disabled: previewMode,
          customRules: [
            {
              validator: async (_, value: SignaturePad) => {
                if (!required) {
                  return Promise.resolve();
                }

                if (value && value.isEmpty()) {
                  return Promise.reject(t('field.required'));
                }

                return Promise.resolve();
              },
            },
          ],
          dataUrl: existingData?.[key],
        };
    }

    if (!field) {
      return <></>;
    }

    return (
      <Form.Item
        key={field.fieldKey}
        name={field.fieldKey}
        label={field.label}
        rules={[{ required: field.required, message: 'This field is required' }, ...(field.customRules ?? [])]}
        dependencies={field.dependencies}
        validateTrigger={field.control === ControlType.Signature ? 'onSubmit' : 'onChange'}
      >
        {getField(field)}
      </Form.Item>
    );
  };

  return (
    <Form
      onFinish={onFinish}
      name={name}
      form={form}
      layout='vertical'
      variant='outlined'
      requiredMark='optional'
      className={className}
      disabled={submitting || previewMode}
      initialValues={existingData}
    >
      {components.map((component) => (
        <Fragment key={component.key}>
          {component.type === CustomFormTemplateComponentType.RICH_TEXT ? (
            <div className='rich-text mb-4 last:mb-0' dangerouslySetInnerHTML={{ __html: component.content }} />
          ) : (
            renderFormField(component)
          )}
        </Fragment>
      ))}
      {!previewMode && (
        <div className='flex mt-8 justify-end'>
          {cancelButton && <SharedButton type='button' {...cancelButton} disabled={submitting} />}
          <SharedButton labelKey='common.submit' type='submit' appearance='primary' loading={submitting} />
        </div>
      )}
    </Form>
  );
};

export default SharedCustomForm;
