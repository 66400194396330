export enum PatientAlertType {
  REVIEW = 'review',
  GENERAL_FOLLOW_UP = 'generalFollowUp',
  RE_TEST = 'reTest',
}

export const PatientAlertTypeData = {
  [PatientAlertType.REVIEW]: {
    value: PatientAlertType.REVIEW,
    translationLabelKey: 'patients.patient.alerts.type.review',
  },
  [PatientAlertType.GENERAL_FOLLOW_UP]: {
    value: PatientAlertType.GENERAL_FOLLOW_UP,
    translationLabelKey: 'patients.patient.alerts.type.general_follow_up',
  },
  [PatientAlertType.RE_TEST]: {
    value: PatientAlertType.RE_TEST,
    translationLabelKey: 'patients.patient.alerts.type.re_test',
  },
};

export const PatientAlertTypeOptions = [
  PatientAlertType.REVIEW,
  PatientAlertType.GENERAL_FOLLOW_UP,
  PatientAlertType.RE_TEST,
];
