export enum ControlType {
  TextField = 'textField',
  NumberField = 'numberField',
  PhoneNumber = 'phoneNumber',
  File = 'file',
  Select = 'select',
  SelectMultipleTags = 'selectMultipleTags',
  Checkbox = 'checkbox',
  Address = 'address',
  ColorPicker = 'colorPicker',
  DatePicker = 'datePicker',
  CheckboxGroup = 'checkboxGroup',
  TextArea = 'textArea',
  Switch = 'switch',
  RadioButton = 'radioButton',
  Signature = 'signature',
  DateRangePicker = 'dateRangePicker',
  RichText = 'richText',
  UserSearch = 'userSearch',
}

export const ControlTypeData = {
  [ControlType.TextField]: {
    value: ControlType.TextField,
    translationLabelKey: 'control_type.text_field',
  },
  [ControlType.NumberField]: {
    value: ControlType.NumberField,
    translationLabelKey: 'control_type.number_field',
  },
  [ControlType.PhoneNumber]: {
    value: ControlType.PhoneNumber,
    translationLabelKey: 'control_type.phone_number',
  },
  [ControlType.File]: {
    value: ControlType.File,
    translationLabelKey: 'control_type.file',
  },
  [ControlType.Select]: {
    value: ControlType.Select,
    translationLabelKey: 'control_type.select',
  },
  [ControlType.SelectMultipleTags]: {
    value: ControlType.SelectMultipleTags,
    translationLabelKey: 'control_type.select_multiple_tags',
  },
  [ControlType.Checkbox]: {
    value: ControlType.Checkbox,
    translationLabelKey: 'control_type.checkbox',
  },
  [ControlType.Address]: {
    value: ControlType.Address,
    translationLabelKey: 'control_type.address',
  },
  [ControlType.ColorPicker]: {
    value: ControlType.ColorPicker,
    translationLabelKey: 'control_type.color_picker',
  },
  [ControlType.DatePicker]: {
    value: ControlType.DatePicker,
    translationLabelKey: 'control_type.date_picker',
  },
  [ControlType.CheckboxGroup]: {
    value: ControlType.CheckboxGroup,
    translationLabelKey: 'control_type.checkbox_group',
  },
  [ControlType.TextArea]: {
    value: ControlType.TextArea,
    translationLabelKey: 'control_type.text_area',
  },
  [ControlType.Switch]: {
    value: ControlType.Switch,
    translationLabelKey: 'control_type.switch',
  },
  [ControlType.RadioButton]: {
    value: ControlType.RadioButton,
    translationLabelKey: 'control_type.radio_button',
  },
  [ControlType.Signature]: {
    value: ControlType.Signature,
    translationLabelKey: 'control_type.signature',
  },
  [ControlType.DateRangePicker]: {
    value: ControlType.DateRangePicker,
    translationLabelKey: 'control_type.date_range_picker',
  },
  [ControlType.RichText]: {
    value: ControlType.RichText,
    translationLabelKey: 'control_type.rich_text',
  },
  [ControlType.UserSearch]: {
    value: ControlType.UserSearch,
    translationLabelKey: 'control_type.user_search',
  },
};
