import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { IAppointmentTypeDao, IAppointmentTypeOutcomeDao } from 'core/api/types/appointment-types.interface';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { Alert, App } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { AggregationColor } from 'antd/es/color-picker/color';
import { useState } from 'react';
import { AppointmentTypesApiService } from 'core/api';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { arrayUnion } from 'firebase/firestore';
import _ from 'lodash';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IAddAppointmentTypeFormOutput {
  bannerLabel: string;
  bannerColour: AggregationColor;
  statusTrigger?: string;
}

interface IAddEditAppointmentTypeOutcomeDialog {
  appointmentType: IAppointmentTypeDao;
  outcome?: IAppointmentTypeOutcomeDao;
  excludedStatuses?: string[];
}

const AddEditAppointmentTypeOutcomeDialog = ({
  appointmentType,
  outcome,
  excludedStatuses = [],
}: IAddEditAppointmentTypeOutcomeDialog) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const creating = !outcome;
  const workflowStatuses = useSelector(OrganisationSettingsSlice.selectPatientWorkflowStatuses);
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const { userData } = useUserState();
  const timestamp = getActionTimestampFromUser(userData);

  const fields: ISharedField[] = [
    {
      fieldKey: 'bannerLabel',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('appointment_types.appointment_type_detail.add_edit_outcome.banner_label'),
      required: true,
    },
    {
      fieldKey: 'bannerColour',
      control: ControlType.ColorPicker,
      label: t('appointment_types.appointment_type_detail.add_edit_outcome.banner_colour'),
      required: true,
    },
    {
      fieldKey: 'statusTrigger',
      control: ControlType.Select,
      options:
        workflowStatuses
          ?.filter((status) => !_.includes(excludedStatuses, status.key))
          .map((status) => ({ label: status.name, value: status.key })) ?? [],
      label: t('appointment_types.appointment_type_detail.add_edit_outcome.status_trigger'),
      required: false,
      allowClear: true,
    },
  ];

  const save = async (data: IAddAppointmentTypeFormOutput) => {
    setSubmitting(true);
    try {
      await AppointmentTypesApiService.update(appointmentType.uid, {
        updated: timestamp,
        outcomes: arrayUnion({
          key: uuidv4(),
          bannerColour: data.bannerColour.toHexString(),
          bannerLabel: data.bannerLabel,
          ...(data.statusTrigger && { statusTrigger: data.statusTrigger }),
        }),
      });
      dialog?.closeDialog();
      message.success(t('appointment_types.appointment_type_detail.add_edit_outcome.create.success'));
    } catch (error) {
      message.error(t('appointment_types.appointment_type_detail.add_edit_outcome.create.error'));
      setSubmitting(false);
      sentryCaptureException(error, 'Add appointment type outcome', userData);
    }
  };

  const edit = async (data: IAddAppointmentTypeFormOutput) => {
    setSubmitting(true);
    try {
      await AppointmentTypesApiService.update(appointmentType.uid, {
        updated: timestamp,
        outcomes: appointmentType.outcomes.map((o) =>
          o.key === outcome?.key
            ? {
                key: outcome?.key,
                bannerColour: data.bannerColour.toHexString(),
                bannerLabel: data.bannerLabel,
                ...(data.statusTrigger && { statusTrigger: data.statusTrigger }),
              }
            : o
        ),
      });
      dialog?.closeDialog();
      message.success(t('appointment_types.appointment_type_detail.add_edit_outcome.edit.success'));
    } catch (error) {
      message.error(t('appointment_types.appointment_type_detail.add_edit_outcome.edit.error'));
      setSubmitting(false);
      sentryCaptureException(error, 'Edit appointment type outcome', userData);
    }
  };

  const customContent = () => {
    return (
      <div className='overflow-y-auto p-4'>
        {!workflowStatuses ? (
          <Alert
            message={t('appointment_types.appointment_type_detail.add_edit_outcome.error_loading_data')}
            type='error'
            showIcon
          />
        ) : (
          <SharedForm<IAddAppointmentTypeFormOutput>
            className=''
            onFinish={creating ? save : edit}
            fields={fields}
            cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
            name='add-edit-appointment-type-outcome-form'
            submitting={submitting}
            existingValue={outcome && { ...outcome, bannerColour: new AggregationColor(outcome.bannerColour) }}
          />
        )}
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t(
        creating
          ? 'appointment_types.appointment_type_detail.add_edit_outcome.create.title'
          : 'appointment_types.appointment_type_detail.add_edit_outcome.edit.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditAppointmentTypeOutcomeDialog;
