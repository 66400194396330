export enum Ear {
  LEFT = 'left',
  RIGHT = 'right',
}

export const EarData = {
  [Ear.LEFT]: {
    value: Ear.LEFT,
    translationLabelKey: 'ear.left',
  },
  [Ear.RIGHT]: {
    value: Ear.RIGHT,
    translationLabelKey: 'ear.right',
  },
};

export const EarOptions = [Ear.LEFT, Ear.RIGHT];
