export enum UnavailabilityType {
  HOLIDAY = 'holiday',
  UNAVAILABILITY = 'unavailability',
}

export const UnavailabilityTypeData = {
  [UnavailabilityType.HOLIDAY]: {
    value: UnavailabilityType.HOLIDAY,
    translationLabelKey: 'unavailability_type.holiday',
  },
  [UnavailabilityType.UNAVAILABILITY]: {
    value: UnavailabilityType.UNAVAILABILITY,
    translationLabelKey: 'unavailability_type.unavailability',
  },
};

export const UnavailabilityTypeOptions = [UnavailabilityType.HOLIDAY, UnavailabilityType.UNAVAILABILITY];
