import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import SharedForm from 'shared/form/shared-form';
import { App } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ControlType } from 'core/enums/control-type';
import { PatientApiService } from 'core/api';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IExportPatientsDialogFormOutput {
  dates: [Dayjs, Dayjs];
}

const ExportPatientsDialog = () => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();

  const submit = async (data: IExportPatientsDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const dateFrom = data.dates[0].format('DD-MM-YYYY');
      const dateTo = data.dates[1].format('DD-MM-YYYY');
      const csv = await PatientApiService.exportPatients({
        dateFrom,
        dateTo,
      });
      const blob = new Blob(['\ufeff', csv]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `patients_${dateFrom}_to_${dateTo}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dialog?.closeDialog();
    } catch (error) {
      sentryCaptureException(error, 'Export patients', userData);
      message.error(t('patients.export_patients.error'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IExportPatientsDialogFormOutput>
        className='overflow-y-auto p-4'
        onFinish={submit}
        fields={[
          {
            fieldKey: 'dates',
            control: ControlType.DateRangePicker,
            maxDate: dayjs(),
            label: t('patients.export_patients.form.date_range'),
            required: true,
            fullWidth: true,
          },
        ]}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-patient-form'
        existingValue={{ dates: [dayjs(), dayjs()] }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.export_patients.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default ExportPatientsDialog;
