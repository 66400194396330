import { message } from 'antd';
import { CustomFormService } from 'core/api';
import { IGetCustomFormResponse } from 'core/api/types/custom-form.interface';
import { useTheme } from 'core/providers/theme-provider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import SharedSpinner from 'shared/spinner/spinner';
import { StopTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedCustomForm from 'shared/custom-form/custom-form';
import { CustomFormTemplateComponentType } from 'core/constants/custom-form-component-type';
import { ControlType } from 'core/enums/control-type';
import { Timestamp } from 'firebase/firestore';

const PublicForm = () => {
  const { uid } = useParams();
  const { primary } = useTheme();
  const [response, setResponse] = useState<IGetCustomFormResponse>();
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);
  const { t } = useTranslation();
  const [errorState, setErrorState] = useState<{ title: string; description: string }>();
  const [initializing, setInitializing] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);

  const getFormFromUid = useCallback(
    async (uid: string) => {
      try {
        const response = await CustomFormService.getCustomForm(uid);
        setResponse(response);
        switch (response.state) {
          case 'formNotFound':
            setErrorState({
              title: 'public_form.not_found.title',
              description: 'public_form.not_found.description',
            });
            break;
          case 'formExpired':
            setErrorState({
              title: 'public_form.expired.title',
              description: 'public_form.expired.description',
            });
            break;
          case 'formComplete':
            setFormSubmitted(true);
            break;
        }
        setInitializing(false);
      } catch (error) {
        message.error(t('public_form.error_getting_form'));
        sentryCaptureException(error, 'Getting public form');
      }
    },
    [t]
  );

  useEffect(() => {
    if (uid) {
      getFormFromUid(uid);
    }
  }, [getFormFromUid, uid]);

  const submit = async (data: Record<string, any>) => {
    try {
      setSubmitting(true);
      const formValues = response?.components
        ?.filter((component) => component.type === CustomFormTemplateComponentType.FIELD)
        .reduce((acc: Record<string, any>, component) => {
          const value = data[component.key];
          if (!value) {
            return acc;
          }
          if (component.control === ControlType.DatePicker) {
            acc[component.key] = Timestamp.fromDate(value.toDate());
          } else if (component.control === ControlType.Signature) {
            acc[component.key] = value.toDataURL();
          } else {
            acc[component.key] = value;
          }
          return acc;
        }, {});
      await CustomFormService.update(uid!, {
        formValues,
        completed: true,
        updated: {
          at: Timestamp.now(),
          by: {
            uid: 'public',
            fullName: 'Public User',
          },
        },
      });
      setFormSubmitted(true);
    } catch (error) {
      message.error(t('public_form.submit.error'));
      sentryCaptureException(error, 'Submitting public form');
      setSubmitting(false);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center p-4 py-16' style={{ minHeight: screenHeight }}>
      {initializing ? (
        <SharedSpinner color={primary.bg} />
      ) : (
        <>
          {response?.organisationBranding?.logoUrl && (
            <img
              src={response.organisationBranding.logoUrl}
              alt='organisation-logo'
              className='max-h-[72px] mb-8 lg:mb-10 mx-auto px-16'
            />
          )}
          <SharedCard outerClassName='max-w-[900px] w-full p-8'>
            {errorState && (
              <div className='flex flex-col justify-center items-center'>
                <StopTwoTone twoToneColor='#dc2626' className='text-3xl mb-4' />
                <p className='body-md mb-1'>{t(errorState.title)}</p>
                <p className='text-gray-500'>{t(errorState.description)}</p>
              </div>
            )}
            {response?.state === 'formFound' && !formSubmitted && (
              <div>
                <p className='header-lg mb-6' style={{ color: response.organisationBranding?.primaryColour }}>
                  {response.name}
                </p>
                <SharedCustomForm<Record<string, any>>
                  submitting={submitting}
                  onFinish={submit}
                  name={response.name ?? 'public-form'}
                  components={response.components ?? []}
                />
              </div>
            )}
            {formSubmitted && (
              <div className='flex flex-col justify-center items-center'>
                <CheckCircleTwoTone twoToneColor='#16a34a' className='text-3xl mb-4' />
                <p className='body-md mb-1'>{t('public_form.submitted.title')}</p>
                <p className='text-gray-500 text-center'>{t('public_form.submitted.description')}</p>
              </div>
            )}
          </SharedCard>
        </>
      )}
    </div>
  );
};

export default PublicForm;
