import { createContext, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

interface IResponsiveContext {
  isMobile: boolean;
  isTablet: boolean;
}

export const ResponsiveContext = createContext<IResponsiveContext | null>(null);

export const useResponsiveState = () => {
  const state = useContext(ResponsiveContext);
  return { ...state };
};

export const ResponsiveProvider = ({ children }: any) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1400px)' });
  return <ResponsiveContext.Provider value={{ isMobile, isTablet }}>{children}</ResponsiveContext.Provider>;
};
