import { App, Tag } from 'antd';
import { CustomFormService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { ICustomFormDao } from 'core/api/types/custom-form.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { orderBy, Unsubscribe, where } from 'firebase/firestore';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import ViewCompletedPatientFormDialog from './view-completed-patient-form-dialog';
import AddPatientFormDialog from './add-patient-form-dialog';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'patients.patient.forms.table.header.sent',
    key: 'created',
    contentTemplateId: 'created',
  },
  {
    labelKey: 'patients.patient.forms.table.header.name',
    key: 'name',
    contentTemplateId: 'name',
  },
  {
    labelKey: 'patients.patient.forms.table.header.status',
    key: 'status',
    contentTemplateId: 'status',
  },
  { key: 'action', contentTemplateId: 'actions', width: 120 },
];

const PatientFormsList = (patient: IPatientDao) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [forms, setForms] = useState<ICustomFormDao[]>([]);
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { userData } = useUserState();
  const customFormTemplateState = useSelector(OrganisationSettingsSlice.selectCustomFormTemplates);
  const dialog = useDialog();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = CustomFormService.onCollectionSnapshot(
      (snap) => {
        setForms(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.forms.error'));
        sentryCaptureException(error, 'Patient file fetching forms', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patientUid', '==', patient.uid),
        orderBy('updated.at', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const nameTemplate = (form: ICustomFormDao) => {
    const customFormTemplate = customFormTemplateState?.data.find((template) => template.uid === form.templateUid);
    return <p>{customFormTemplate?.name ?? t('common.unknown')}</p>;
  };

  const statusTemplate = (form: ICustomFormDao) => {
    let statusColor = 'default';
    let statusLabel = 'patients.patient.forms.table.status.pending';

    if (form.completed) {
      statusColor = 'success';
      statusLabel = 'patients.patient.forms.table.status.completed';
    } else if (form.expires.toDate() < new Date()) {
      statusColor = 'error';
      statusLabel = 'patients.patient.forms.table.status.expired';
    }
    return <Tag color={statusColor}>{t(statusLabel)}</Tag>;
  };

  const actionTemplate = (form: ICustomFormDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        {form.completed && (
          <SharedButton
            onClick={() => dialog?.openDialog(<ViewCompletedPatientFormDialog form={form} />)}
            type='button'
            appearance='link'
            labelKey='common.view'
            primaryOverride
          />
        )}
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENT_FORMS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => CustomFormService.permDelete(form.uid)}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('patients.patient.forms.delete_form.title')}
                  textContent={t('patients.patient.forms.delete_form.content')}
                  successMessage={t('patients.patient.forms.delete_form.success')}
                  errorMessage={t('patients.patient.forms.delete_form.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  return (
    <SharedCard
      title={t('patients.patient.forms.title')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENT_FORMS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddPatientFormDialog patient={patient} />)}
            type='button'
            appearance='link'
            labelKey='patients.patient.forms.new_form'
            primaryOverride
          />
        </SharedElementPermissionGuard>
      }
    >
      <SharedTable
        loading={loading}
        rows={forms.map((form) => ({
          key: form.uid,
          data: form,
        }))}
        columns={tableColumns}
        contentTemplates={[
          {
            id: 'status',
            template: statusTemplate,
          },
          {
            id: 'name',
            template: nameTemplate,
          },
          {
            id: 'actions',
            template: actionTemplate,
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientFormsList;
