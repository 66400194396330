import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { App, Form, UploadFile } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { PatientApiService } from 'core/api';

const BulkAddPatientsDialog = () => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const dialog = useDialog();
  const [form] = Form.useForm();

  const uploadedFiles = useWatch<UploadFile[]>('fileCapture', form);

  const [submitting, setSubmitting] = useState(false);
  const [uploadDataUrls, setUploadDataUrls] = useState<(string | ArrayBuffer | null)[]>();

  const reader = useMemo(() => {
    return new FileReader();
  }, []);

  useEffect(() => {
    if (uploadedFiles) {
      const urls: (string | ArrayBuffer | null)[] = [];

      uploadedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          urls.push(reader.result);
        };
        reader.readAsDataURL(file as any);
      });

      setUploadDataUrls(urls);
    }
  }, [uploadedFiles, reader]);

  const fields: ISharedField[] = [
    {
      fieldKey: 'fileCapture',
      control: ControlType.File,
      label: 'File',
      required: true,
      maxFileSize: 10000000,
      acceptedFileTypes: ['text/csv'],
    },
    {
      label: 'Source system',
      fieldKey: 'sourceSystem',
      control: ControlType.Select,
      options: [
        { label: 'Strato', value: 'strato' },
        { label: 'Manage', value: 'manage' },
        { label: 'Sycle', value: 'sycle' },
      ],
      required: true,
    },
    {
      fieldKey: 'start',
      control: ControlType.NumberField,
      label: 'Start',
      required: false,
    },
    {
      fieldKey: 'finish',
      control: ControlType.NumberField,
      label: 'Finish',
      required: false,
    },
  ];

  const submit = async (data: any) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      await PatientApiService.importPatients(
        uploadDataUrls?.map((url) => url?.toString() as string) ?? [],
        data.sourceSystem,
        data.start,
        data.finish
      );
      message.success(t('patients.bulk_add_patients_dialog.success'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('patients.bulk_add_patients_dialog.error'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <>
        <SharedForm
          formInstance={form}
          className='overflow-y-auto p-4'
          onFinish={submit}
          fields={fields}
          submitting={submitting}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          name='bulk-add-patients'
        />
      </>
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.bulk_add_patients_dialog.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default BulkAddPatientsDialog;
