import { Navigate, Route, Routes } from 'react-router-dom';
import HolidaysAndAvailabilityList from './holidays-and-unavailability-list';

const HolidaysAndUnavailabilityRouting = () => {
  return (
    <Routes>
      <Route path='' element={<HolidaysAndAvailabilityList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default HolidaysAndUnavailabilityRouting;
