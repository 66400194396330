import { Switch } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { IHearingAidDao } from 'core/api/types';
import { IOrderDao } from 'core/api/types/order.interface';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import {
  OrganisationSettingsSlice,
  IDomainOrganisationDataType,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedButton from 'shared/button/button';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';

export interface IAddEditOrderHearingAidsEarFormOutput {
  manufacturer: string;
  model: string;
  style: string;
  power: string;
  colour: string;
  price: number;
  receiver?: string;
  gain?: string;
  earbudSize?: string;
  earbudType?: string;
}

interface IAddEditOrderHearingAidsEar {
  order?: IOrderDao;
  form: FormInstance;
  label: string;
  copyToOtherEar: (key: 'left' | 'right') => void;
  enabled: boolean;
  toggleEnabled: (key: 'left' | 'right') => void;
  earKey: 'left' | 'right';
}

const AddEditOrderHearingAidsEar = ({
  order,
  form,
  label,
  copyToOtherEar,
  enabled,
  toggleEnabled,
  earKey,
}: IAddEditOrderHearingAidsEar) => {
  const hearingAidState = useSelector(OrganisationSettingsSlice.selectHearingAids);
  const manufacturersList = [
    ...new Set(
      hearingAidState?.data.filter((hearingAid) => !hearingAid.deleted).map((hearingAid) => hearingAid.manufacturer)
    ),
  ];
  const { t } = useTranslation();
  const selectedManufacturer = useWatch('manufacturer', form);
  const selectedModel = useWatch('model', form);
  const selectedStyle = useWatch('style', form);
  const selectedPower = useWatch('power', form);
  const selectedReceiver = useWatch('receiver', form);
  const selectedGain = useWatch('gain', form);
  const selectedEarbudSize = useWatch('earbudSize', form);
  const selectedEarbudType = useWatch('earbudType', form);
  const [availableModels, setAvailableModels] = useState<string[]>([]);
  const [availableStyles, setAvailableStyles] = useState<string[]>([]);
  const [matchedAid, setMatchedAid] = useState<IDomainOrganisationDataType<IHearingAidDao>>();

  const fields: ISharedField[] = [
    {
      fieldKey: 'manufacturer',
      control: ControlType.Select,
      options: manufacturersList.map((manufacturer) => ({ label: manufacturer, value: manufacturer })),
      disabled: !manufacturersList?.length,
      label: t('order.add_edit_order.hearing_aids.form.manufacturer'),
      required: true,
    },
    {
      fieldKey: 'model',
      control: ControlType.Select,
      options: availableModels.map((model) => ({ label: model, value: model })),
      disabled: !availableModels?.length,
      label: t('order.add_edit_order.hearing_aids.form.model'),
      required: true,
      hidden: !selectedManufacturer,
    },
    {
      fieldKey: 'style',
      control: ControlType.Select,
      options: availableStyles.map((model) => ({ label: model, value: model })),
      disabled: !availableStyles?.length,
      label: t('order.add_edit_order.hearing_aids.form.style'),
      required: true,
      hidden: !selectedManufacturer || !selectedModel,
    },
    {
      fieldKey: 'receiver',
      control: ControlType.Select,
      options: matchedAid?.receivers?.map((receiver) => ({ label: receiver, value: receiver })) ?? [],
      disabled: !matchedAid?.receivers?.length,
      label: t('order.add_edit_order.hearing_aids.form.receiver'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'gain',
      control: ControlType.Select,
      options: matchedAid?.gainOptions?.map((gain) => ({ label: gain, value: gain })) ?? [],
      disabled: !matchedAid?.gainOptions?.length,
      label: t('order.add_edit_order.hearing_aids.form.gain'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'earbudSize',
      control: ControlType.Select,
      options: matchedAid?.earbudSize?.map((earbudSize) => ({ label: earbudSize, value: earbudSize })) ?? [],
      disabled: !matchedAid?.earbudSize?.length,
      label: t('order.add_edit_order.hearing_aids.form.earbud_size'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'earbudType',
      control: ControlType.Select,
      options: matchedAid?.earbudType?.map((earbudType) => ({ label: earbudType, value: earbudType })) ?? [],
      disabled: !matchedAid?.earbudType?.length,
      label: t('order.add_edit_order.hearing_aids.form.earbud_type'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'power',
      control: ControlType.Select,
      options: matchedAid?.power.map((power) => ({ label: power, value: power })) ?? [],
      disabled: !matchedAid?.power.length,
      label: t('order.add_edit_order.hearing_aids.form.power'),
      required: true,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'colour',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('order.add_edit_order.hearing_aids.form.colour'),
      required: true,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'price',
      control: ControlType.NumberField,
      min: 0,
      label: t('common.price'),
      required: true,
      hidden: !matchedAid,
      fullWidth: true,
    },
  ];

  const resetFields = useCallback(
    (fields: string[]) => {
      fields.forEach((field) => form.setFieldsValue({ [field]: undefined }));
    },
    [form]
  );

  useEffect(() => {
    if (selectedManufacturer) {
      const models = [
        ...new Set(
          hearingAidState?.data
            .filter((hearingAid) => !hearingAid.deleted && hearingAid.manufacturer === selectedManufacturer)
            .map((hearingAid) => hearingAid.model)
        ),
      ];
      setAvailableModels(models);
      // if (selectedModel && !models.includes(selectedModel)) {
      //   resetFields(['model', 'style', 'power']);
      // }
    }
  }, [form, hearingAidState?.data, resetFields, selectedManufacturer, selectedModel]);

  useEffect(() => {
    if (selectedModel) {
      const styles = [
        ...new Set(
          hearingAidState?.data
            .filter(
              (hearingAid) =>
                !hearingAid.deleted &&
                hearingAid.manufacturer === selectedManufacturer &&
                hearingAid.model === selectedModel
            )
            .map((hearingAid) => hearingAid.style)
        ),
      ];
      setAvailableStyles(styles);
      // if (selectedStyle && !styles.includes(selectedStyle)) {
      //   resetFields(['style', 'power']);
      // }
    }
  }, [form, hearingAidState?.data, resetFields, selectedManufacturer, selectedModel, selectedStyle]);

  useEffect(() => {
    if (selectedStyle) {
      const matchedAid = hearingAidState?.data.find(
        (hearingAid) =>
          hearingAid.manufacturer === selectedManufacturer &&
          hearingAid.model === selectedModel &&
          hearingAid.style === selectedStyle
      );
      setMatchedAid(matchedAid);
      if (matchedAid && form.getFieldValue('price') === undefined) {
        form.setFieldsValue({
          price: matchedAid.rrp,
        });
      }
      // if (selectedPower && !matchedAid?.power.includes(selectedPower)) {
      //   resetFields(['power']);
      // }
      // if (selectedReceiver && !matchedAid?.receivers?.includes(selectedReceiver)) {
      //   resetFields(['receiver']);
      // }
      // if (selectedGain && !matchedAid?.gainOptions.includes(selectedGain)) {
      //   resetFields(['gain']);
      // }
      // if (selectedEarbudSize && !matchedAid?.earbudSize?.includes(selectedEarbudSize)) {
      //   resetFields(['earbudSize']);
      // }
      // if (selectedEarbudType && !matchedAid?.earbudType?.includes(selectedEarbudType)) {
      //   resetFields(['earbudType']);
      // }
    }
  }, [
    form,
    hearingAidState?.data,
    resetFields,
    selectedEarbudSize,
    selectedEarbudType,
    selectedGain,
    selectedManufacturer,
    selectedModel,
    selectedPower,
    selectedReceiver,
    selectedStyle,
  ]);

  return (
    <div className='border-r last:border-0 p-4'>
      <div className='flex items-center justify-between'>
        <p className='font-semibold'>{label}</p>
        <Switch value={enabled} onChange={() => toggleEnabled(earKey)} />
      </div>

      {enabled && (
        <>
          <SharedForm<IAddEditOrderHearingAidsEarFormOutput>
            formInstance={form}
            fields={fields}
            name={earKey}
            className='mt-4'
            buttonsOverride={[]}
            existingValue={{
              ...order?.hearingAids?.[earKey],
            }}
          />
          <SharedButton
            onClick={() => copyToOtherEar(earKey)}
            appearance='link'
            primaryOverride
            labelKey={'order.add_edit_order.hearing_aids.form.copy_to_other_ear'}
          />
        </>
      )}
    </div>
  );
};

export default AddEditOrderHearingAidsEar;
