export enum CalendarTimeframe {
  DAY = 'day',
  WEEK = 'week',
}

export const CalendarTimeframeData = {
  [CalendarTimeframe.DAY]: {
    value: CalendarTimeframe.DAY,
    translationLabelKey: 'calendar.settings_drawer.timeframe.day',
  },
  [CalendarTimeframe.WEEK]: {
    value: CalendarTimeframe.WEEK,
    translationLabelKey: 'calendar.settings_drawer.timeframe.week',
  },
};

export const CalendarTimeframeOptions = [CalendarTimeframe.DAY, CalendarTimeframe.WEEK];
