import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { Drawer, Segmented } from 'antd';
import { Settings } from 'react-feather';
import FormItemMimic from 'shared/form/form-item-mimic';
import { CalendarMode, CalendarModeData, CalendarModeOptions } from 'core/constants/calendar-mode';
import { CalendarTimeframe, CalendarTimeframeData, CalendarTimeframeOptions } from 'core/constants/calendar-timeframe';

interface ICalendarSettingsDrawer {
  mode: string;
  timeframe: string;
  handleToggleChange: (key: string, value: string) => void;
}

const CalendarSettingsDrawer = ({ mode, timeframe, handleToggleChange }: ICalendarSettingsDrawer) => {
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWeeklyViewToggle, setShowWeeklyViewToggle] = useState<boolean>(true);

  useEffect(() => {
    if (mode === CalendarMode.CLINICS) {
      setShowWeeklyViewToggle(false);
      handleToggleChange('timeframe', CalendarTimeframe.DAY);
    } else {
      setShowWeeklyViewToggle(true);
    }
  }, [handleToggleChange, mode, t]);

  return (
    <>
      <SharedButton appearance='default' icon={<Settings size={20} />} onClick={() => setOpenDrawer(true)} />
      <Drawer title={t('calendar.settings_drawer.title')} onClose={() => setOpenDrawer(false)} open={openDrawer}>
        <FormItemMimic label={t('calendar.settings_drawer.mode')}>
          <Segmented
            value={mode}
            options={CalendarModeOptions.map((mode) => {
              const data = CalendarModeData[mode];
              return {
                value: data.value,
                label: t(data.translationLabelKey),
              };
            })}
            onChange={(value) => handleToggleChange('mode', value)}
          />
        </FormItemMimic>
        {showWeeklyViewToggle && (
          <FormItemMimic label={t('calendar.settings_drawer.timeframe')}>
            <Segmented
              value={timeframe}
              options={CalendarTimeframeOptions.map((mode) => {
                const data = CalendarTimeframeData[mode];
                return {
                  value: data.value,
                  label: t(data.translationLabelKey),
                };
              })}
              onChange={(value) => handleToggleChange('timeframe', value)}
            />
          </FormItemMimic>
        )}
      </Drawer>
    </>
  );
};

export default CalendarSettingsDrawer;
