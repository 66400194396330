import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useMemo, useState } from 'react';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { OrganisationsApiService } from 'core/api';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { storage } from 'core/config/firebase';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { OrganisationStatus } from 'core/constants/organisation-status';
import { IOrganisationDao } from 'core/api/types';
import { App, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FileType } from 'shared/fields/file-upload';
import { Color } from 'antd/es/color-picker';
import { AggregationColor } from 'antd/es/color-picker/color';
import { Timestamp } from 'firebase/firestore';

const orgNameK = 'name';
const logoWhiteK = 'logoWhite';
const logoDarkK = 'logoDark';
const primaryBgK = 'primaryBg';
const primaryTextK = 'primaryText';

interface IAdminAddEditOrganisationFormOutput {
  [key: string]: string | Color | undefined | FileType[];
  [orgNameK]: string;
  [logoWhiteK]: FileType[];
  [logoDarkK]: FileType[];
  [primaryBgK]: Color;
  [primaryTextK]: Color;
}

interface IAdminAddEditOrganisationDialog {
  organisation?: IOrganisationDao;
}

const AdminAddEditOrganisationDialog = ({ organisation }: IAdminAddEditOrganisationDialog) => {
  const [submitting, setSubmitting] = useState(false);
  const creating = !organisation;
  const dialog = useDialog();
  const { t } = useTranslation();
  const { userData } = useUserState();
  const [logoWhiteDataUrl, setLogoWhiteDataUrl] = useState<string | ArrayBuffer | null>();
  const [logoDarkDataUrl, setLogoDarkDataUrl] = useState<string | ArrayBuffer | null>();
  const reader = useMemo(() => {
    return new FileReader();
  }, []);
  const [form] = Form.useForm();
  const logoWhiteFiles = useWatch(logoWhiteK, form);
  const logoDarkFiles = useWatch(logoDarkK, form);
  const selectedBackgroundColor = useWatch<Color | undefined>(primaryBgK, form);
  const selectedTextColor = useWatch<Color | undefined>(primaryTextK, form);
  const { message } = App.useApp();

  useEffect(() => {
    const getLogos = async (organisation: IOrganisationDao) => {
      try {
        const promises = [getDownloadURL(ref(storage, organisation.themeData.logoWhitePath))];
        if (organisation.themeData.logoDarkPath) {
          promises.push(getDownloadURL(ref(storage, organisation.themeData.logoDarkPath)));
        }
        const [white, dark] = await Promise.all(promises);
        setLogoWhiteDataUrl(white);
        setLogoDarkDataUrl(dark);
      } catch (error) {
        message.error(t('admin.add_edit_organisation.logo.fetch_error'));
      }
    };
    if (organisation) {
      getLogos(organisation);
    }
  }, [message, organisation, t]);

  useEffect(() => {
    if (logoWhiteFiles) {
      if (logoWhiteFiles.length === 0) {
        setLogoWhiteDataUrl(undefined);
      } else {
        reader.onloadend = () => {
          setLogoWhiteDataUrl(reader.result);
        };
        reader.readAsDataURL(logoWhiteFiles[0]);
      }
    }
  }, [logoWhiteFiles, reader]);

  useEffect(() => {
    if (logoDarkFiles) {
      if (logoDarkFiles.length === 0) {
        setLogoDarkDataUrl(undefined);
      } else {
        reader.onloadend = () => {
          setLogoDarkDataUrl(reader.result);
        };
        reader.readAsDataURL(logoDarkFiles[0]);
      }
    }
  }, [logoDarkFiles, reader]);

  const fields: ISharedField[] = [
    {
      fieldKey: orgNameK,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('admin.add_edit_organisation.name'),
      required: true,
    },
    {
      fieldKey: logoWhiteK,
      control: ControlType.File,
      label: t('admin.add_edit_organisation.logo_white'),
      required: creating,
      acceptedFileTypes: ['image/png'],
      maxCount: 1,
      maxFileSize: 2000000,
    },
    {
      fieldKey: logoDarkK,
      control: ControlType.File,
      label: t('admin.add_edit_organisation.logo_dark'),
      required: creating,
      acceptedFileTypes: ['image/png'],
      maxCount: 1,
      maxFileSize: 2000000,
    },
    {
      fieldKey: primaryBgK,
      control: ControlType.ColorPicker,
      label: t('admin.add_edit_organisation.background_colour'),
      required: true,
      disabledAlpha: true,
    },
    {
      fieldKey: primaryTextK,
      control: ControlType.ColorPicker,
      label: t('admin.add_edit_organisation.text_colour'),
      required: true,
    },
  ];

  const addOrganisation = async (data: IAdminAddEditOrganisationFormOutput) => {
    setSubmitting(true);
    try {
      const uid = uuidv4();
      const logoWhitePath = `logos/${uid}/logoWhite.png`;
      const logoDarkPath = `logos/${uid}/logoDark.png`;
      await Promise.all([
        uploadString(ref(storage, logoWhitePath), logoWhiteDataUrl!.toString(), 'data_url'),
        uploadString(ref(storage, logoDarkPath), logoDarkDataUrl!.toString(), 'data_url'),
      ]);

      const userTimestamp = getActionTimestampFromUser(userData);
      await OrganisationsApiService.set({
        uid,
        created: userTimestamp,
        updated: userTimestamp,
        name: data[orgNameK],
        themeData: {
          [primaryBgK]: data[primaryBgK]?.toHexString(),
          [primaryTextK]: data[primaryTextK]?.toHexString(),
          logoWhitePath,
          logoDarkPath,
        },
        features: [],
        finance: {
          vatEnabled: false,
          vatStandardRate: 0,
          currency: 'GBP',
          invoice: {
            prefix: 'INV',
            next: 1,
            digits: 5,
          },
        },
        calendar: {
          startTime: Timestamp.fromDate(new Date('9999-01-01T08:00:00Z')),
          endTime: Timestamp.fromDate(new Date('9999-01-01T17:00:00Z')),
        },
        status: OrganisationStatus.ACTIVE,
      });
      dialog?.closeDialog();
      message.success(t('admin.add_edit_organisation.create.success.description'));
    } catch (error) {
      message.error(t('admin.add_edit_organisation.create.error.description'));
      setSubmitting(false);
    }
  };

  const editOrganisation = async (data: IAdminAddEditOrganisationFormOutput) => {
    setSubmitting(true);
    try {
      if (!organisation) {
        throw new Error(t('admin.add_edit_organisation.edit.error.description'));
      }
      const logoWhitePath = `logos/${organisation.uid}/logoWhite.png`;
      if (data[logoWhiteK]) {
        await uploadString(ref(storage, logoWhitePath), logoWhiteDataUrl!.toString(), 'data_url');
      }
      const logoDarkPath = `logos/${organisation.uid}/logoDark.png`;
      if (data[logoDarkK]) {
        await uploadString(ref(storage, logoDarkPath), logoDarkDataUrl!.toString(), 'data_url');
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      await OrganisationsApiService.update(organisation.uid, {
        name: data[orgNameK],
        themeData: {
          [primaryBgK]: data[primaryBgK]?.toHexString(),
          [primaryTextK]: data[primaryTextK]?.toHexString(),
          logoWhitePath: logoWhitePath,
          logoDarkPath: logoDarkPath,
        },
        updated: userTimestamp,
      });
      dialog?.closeDialog();
      message.success(t('admin.add_edit_organisation.edit.success.description'));
    } catch (error) {
      message.error(t('admin.add_edit_organisation.edit.error.description'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <div className='overflow-y-auto p-4'>
        <div
          style={{ backgroundColor: selectedBackgroundColor?.toHexString() ?? 'gray' }}
          className='h-[80px] border mb-4 p-4 flex justify-between items-center relative'
        >
          {logoWhiteDataUrl && <img src={logoWhiteDataUrl.toString()} alt='logo' className='max-h-[70%]' />}
          <p className='body-sm' style={{ color: selectedTextColor?.toHexString() ?? '#d1d5db' }}>
            Text preview
          </p>
        </div>
        <div className='h-[80px] border mb-4 p-4 flex justify-between items-center bg-white'>
          {logoDarkDataUrl && <img src={logoDarkDataUrl.toString()} alt='logo' className='max-h-[70%]' />}
        </div>
        <SharedForm
          formInstance={form}
          name='add-edit-organisation'
          fields={fields}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          onFinish={creating ? addOrganisation : editOrganisation}
          className='p-0'
          submitting={submitting}
          existingValue={
            organisation && {
              [orgNameK]: organisation.name,
              [primaryBgK]: new AggregationColor(organisation.themeData.primaryBg),
              [primaryTextK]: new AggregationColor(organisation.themeData.primaryText),
            }
          }
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'admin.add_edit_organisation.title.creating' : 'admin.add_edit_organisation.title.editing')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AdminAddEditOrganisationDialog;
