export enum RepeatFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const RepeatFrequencyData = {
  [RepeatFrequency.DAILY]: {
    value: RepeatFrequency.DAILY,
    translationLabelKey: 'repeat_frequency.daily',
  },
  [RepeatFrequency.WEEKLY]: {
    value: RepeatFrequency.WEEKLY,
    translationLabelKey: 'repeat_frequency.weekly',
  },
  [RepeatFrequency.MONTHLY]: {
    value: RepeatFrequency.MONTHLY,
    translationLabelKey: 'repeat_frequency.monthly',
  },
  [RepeatFrequency.YEARLY]: {
    value: RepeatFrequency.YEARLY,
    translationLabelKey: 'repeat_frequency.yearly',
  },
};

export const RepeatFrequencyOptions = [
  RepeatFrequency.DAILY,
  RepeatFrequency.WEEKLY,
];
