import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useSelector } from 'react-redux';

const AlertsSidebarCounter = () => {
  const alertsState = useSelector(OrganisationSettingsSlice.selectPatientAlerts);
  const showCount = alertsState?.status === 'success' && alertsState?.data.length > 0;

  return showCount ? (
    <div className='absolute top-1/2 -translate-y-1/2 right-4 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center body-xs'>
      {alertsState?.data.length}
    </div>
  ) : (
    <></>
  );
};

export default AlertsSidebarCounter;
