import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import SharedDialogBase, { ISharedDialogButton } from './dialog-base';
import { Eye, EyeOff } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';

interface IUserPasswordResetDialog {
  fullName: string;
  temporaryPassword: string;
}

const UserPasswordResetDialog = ({ fullName, temporaryPassword }: IUserPasswordResetDialog) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const dialog = useDialog();
  const { message } = App.useApp();

  const password = showPassword
    ? temporaryPassword
    : temporaryPassword
        .split('')
        .map(() => '•')
        .join('');

  const copyPassword = () => {
    navigator.clipboard.writeText(temporaryPassword);
    message.success(t('dialog.new_user_password.success.description'));
  };

  const customContent = () => {
    return (
      <div className='body-sm p-4'>
        <p className='mb-4'>
          <Trans i18nKey='dialog.new_user_password.body' values={{ fullName }}>
            A new password has been created for <span className='font-semibold'></span>. They'll be asked to set a new
            password when they next login.
          </Trans>
        </p>

        <div className='flex items-center'>
          <p>{`${t('common.password')}: ${password}`}</p>
          <div
            className='flex items-center justify-center w-10 text-gray-400 hover:text-gray-700 cursor-pointer'
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
          </div>
        </div>
      </div>
    );
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'copyPassword',
      props: {
        onClick: () => copyPassword(),
        labelKey: 'dialog.new_user_password.copy_password',
        type: 'button',
      },
    },
    {
      key: 'done',
      props: {
        onClick: () => dialog?.closeDialog(),
        labelKey: 'dialog.new_user_password.done',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.new_user_password.title')}
      customContentTemplate={customContent()}
      customButtons={customButtons}
    />
  );
};

export default UserPasswordResetDialog;
