import { Navigate, Route, Routes } from 'react-router-dom';
import BookingWidgetSettings from './booking-widget-settings';

const BookingWidgetSettingsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<BookingWidgetSettings />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default BookingWidgetSettingsRouting;
