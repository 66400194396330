export enum CalendarMode {
  PEOPLE = 'people',
  CLINICS = 'clinics',
}

export const CalendarModeData = {
  [CalendarMode.PEOPLE]: {
    value: CalendarMode.PEOPLE,
    translationLabelKey: 'calendar.settings_drawer.mode.people',
  },
  [CalendarMode.CLINICS]: {
    value: CalendarMode.CLINICS,
    translationLabelKey: 'calendar.settings_drawer.mode.clinics',
  },
};

export const CalendarModeOptions = [CalendarMode.PEOPLE, CalendarMode.CLINICS];
