import { useForm } from 'antd/es/form/Form';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedForm from 'shared/form/shared-form';
import SharedPageHeader from 'shared/page-header/page-header';
import AddEditComponentDialog from './add-edit-custom-form-template-add-edit-component-dialog';
import AddEditCustomFormTemplateComponent from './add-edit-custom-form-template-form-component';
import clsx from 'clsx';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { useSelector } from 'react-redux';
import { StoreState } from 'store';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { CustomFormTemplatesApiService } from 'core/api';
import { ICustomFormTemplateComponent } from 'core/api/types/custom-form-templates.interface';
import AddEditCustomFormTemplatePreviewDialog from './add-edit-custom-form-template-preview-dialog';

const AddEditCustomFormTemplate = () => {
  const { t } = useTranslation();
  const [components, setComponents] = useState<ICustomFormTemplateComponent[]>([]);
  const [customFormDetailsForm] = useForm();
  const dialog = useDialog();
  const [componentsError, setComponentsError] = useState<string>();
  const { userData } = useUserState();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { uid } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const customForm = useSelector((state: StoreState) =>
    OrganisationSettingsSlice.selectCustomFormTemplate(state, uid ?? '')
  );

  useEffect(() => {
    if (components.length > 0) {
      setComponentsError(undefined);
    }
  }, [components]);

  useEffect(() => {
    if (customForm) {
      setComponents(customForm.components);
      customFormDetailsForm.setFieldsValue({
        name: customForm.name,
        description: customForm.description,
      });
    }
  }, [customForm, customFormDetailsForm]);

  const customFormDetailsFormFields: ISharedField[] = [
    {
      fieldKey: 'name',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('form_settings.custom_forms.add_edit_form.details_form.name'),
      required: true,
    },
    {
      fieldKey: 'description',
      control: ControlType.TextArea,
      rows: 4,
      label: t('form_settings.custom_forms.add_edit_form.details_form.description'),
      required: false,
    },
  ];

  const submit = async () => {
    try {
      setSubmitting(true);
      await customFormDetailsForm.validateFields();
      if (components.length === 0) {
        setComponentsError(t('form_settings.custom_forms.add_edit_form.components.no_components_error'));
        return;
      }
      try {
        const { name, description } = customFormDetailsForm.getFieldsValue();
        const userTimestamp = getActionTimestampFromUser(userData);
        const base = {
          name,
          ...(description && { description }),
          components,
          updated: userTimestamp,
        };
        if (uid) {
          await CustomFormTemplatesApiService.update(uid, base);
        } else {
          await CustomFormTemplatesApiService.set({
            ...base,
            uid: uuidv4(),
            created: userTimestamp,
            deleted: false,
            organisationUid: userData?.organisationUid,
          });
        }
        navigate(-1);
        message.success(
          t(
            uid
              ? 'form_settings.custom_forms.add_edit_form.edit.success'
              : 'form_settings.custom_forms.add_edit_form.create.success'
          )
        );
      } catch (error) {
        throw new Error('Error submitting custom form');
      }
    } catch (error) {
      message.error(
        t(
          uid
            ? 'form_settings.custom_forms.add_edit_form.edit.error'
            : 'form_settings.custom_forms.add_edit_form.create.error'
        )
      );
      sentryCaptureException(error, 'Submitting custom form', userData);
      setSubmitting(false);
    }
  };

  const headerActions = [
    {
      element: (
        <SharedButton
          labelKey='form_settings.custom_forms.add_edit_form.preview'
          onClick={() =>
            dialog?.openDialog(
              <AddEditCustomFormTemplatePreviewDialog
                name={customFormDetailsForm.getFieldValue('name')}
                components={components}
              />
            )
          }
        />
      ),
      key: 'submit',
    },
    {
      element: <SharedButton labelKey='common.submit' appearance='primary' onClick={submit} />,
      key: 'submit',
    },
  ];

  return (
    <>
      <SharedPageHeader
        title={t(
          uid
            ? 'form_settings.custom_forms.add_edit_form.edit.title'
            : 'form_settings.custom_forms.add_edit_form.create.title'
        )}
        showBack
        actions={headerActions}
      />
      <SharedForm
        submitting={submitting}
        formInstance={customFormDetailsForm}
        className=''
        name='form_settings.custom_forms.add_edit_form.details_form'
        fields={customFormDetailsFormFields}
        buttonsOverride={[]}
      />
      <FormItemMimic label={t('form_settings.custom_forms.add_edit_form.components')} className='mb-2'>
        <div className={clsx('bg-white border rounded-md', componentsError ? 'border-red-500' : 'border-gray-300')}>
          {components.length === 0 && (
            <p className='text-gray-400 p-4 body-sm'>
              {t('form_settings.custom_forms.add_edit_form.components.no_components')}
            </p>
          )}
          {components.map((component) => (
            <AddEditCustomFormTemplateComponent
              key={component.key}
              component={component}
              setComponents={setComponents}
            />
          ))}
          <div className='px-4 py-1 border-t'>
            <SharedButton
              primaryOverride
              labelKey='form_settings.custom_forms.add_edit_form.components.add_component'
              appearance='link'
              onClick={() => dialog?.openDialog(<AddEditComponentDialog setComponents={setComponents} />)}
            />
          </div>
        </div>
      </FormItemMimic>
      {componentsError && <p className='text-red-500'>{componentsError}</p>}
    </>
  );
};

export default AddEditCustomFormTemplate;
