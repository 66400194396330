import { App } from 'antd';
import clsx from 'clsx';
import { AppointmentsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import { useResponsiveState } from 'core/providers/responsive-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientOverviewAppointmentsWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveState();

  return (
    <SharedCard
      outerClassName='flex flex-col row-span-2'
      title={t('patients.patient.patient_overview.appointments_widget.title')}
      extra={<p className='text-gray-400'>{t('patients.patient.patient_overview.appointments_widget.subtitle')}</p>}
      innerClassName={clsx('grow flex p-4 relative', isMobile && 'h-[300px]')}
    >
      <SharedElementPermissionGuard
        requiredPermissions={[
          [Permission.APPOINTMENTS_READ_ALL],
          [Permission.APPOINTMENTS_READ_MY_CALENDAR],
          [Permission.APPOINTMENTS_READ_MY_CLINICS],
          [Permission.ORGANISATION_OWNER],
        ]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const appointTypesState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const [appointmentTypeCounts, setAppointmentTypeCounts] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true);
      try {
        const apps = await AppointmentsApiService.fetchPatientAppointments(patient.uid);
        const counts: { [key: string]: number } = {};
        apps.forEach((data) => {
          const stateDateTime = dayjs(data.startDateTime.toDate());
          if (
            !data.cancelled &&
            stateDateTime >= dayjs(new Date()).add(-30, 'days').startOf('day') &&
            stateDateTime <= dayjs(new Date()).endOf('day')
          ) {
            if (data?.type) {
              counts[data.type] = (counts[data.type] || 0) + 1;
            }
          }
        });
        setAppointmentTypeCounts(counts);
        setLoading(false);
      } catch (error) {
        message.error(t('patients.patient.patient_overview.appointments_widget.error'));
        sentryCaptureException(error, 'Patient file fetching hearing tests', userData);
      }
    };

    fetchAppointments();
  }, [message, patient.uid, t, userData]);

  const pieData = Object.entries(appointmentTypeCounts).map(([key, val]) => {
    const type = appointTypesState?.data.find((type) => type.uid === key);
    return {
      name: type?.name,
      value: val,
      color: type?.colour,
    };
  });

  return loading ? (
    <>
      <SkeletonElement height='100%' width='100%' />
    </>
  ) : (
    <>
      {pieData.length === 0 ? (
        <p className='absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] text-gray-300'>
          {t('patients.patient.patient_overview.appointments_widget.no_appointments')}
        </p>
      ) : (
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart>
            <Pie
              data={Object.entries(appointmentTypeCounts).map(([key, val]) => ({
                name: appointTypesState?.data.find((type) => type.uid === key)?.name,
                value: val,
              }))}
              dataKey='value'
              nameKey='name'
              label
            >
              {pieData.map((entry) => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Pie>
            <Legend verticalAlign='bottom' height={36} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default PatientOverviewAppointmentsWidget;
