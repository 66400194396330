import { IUserSearchResult } from 'core/api/types';
import SharedUserSearch from 'shared/algolia/user-search';

interface IFormUserSearch {
  id?: string;
  value?: IUserSearchResult;
  onChange?: (value?: IUserSearchResult) => void;
  disabled?: boolean;
}

const FormUserSearch = ({ id, value, onChange, disabled }: IFormUserSearch) => {
  return <SharedUserSearch onSelect={(value) => onChange?.(value)} disabled={disabled} value={value} />;
};

export default FormUserSearch;
