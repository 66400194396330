import { App } from 'antd';
import { OrganisationsApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedPageHeader from 'shared/page-header/page-header';

interface IFittingReceiptSettingsFormOutput {
  companyStatementTitle?: string;
  companyStatementBody: string;
}

const FittingReceiptSettings = () => {
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const save = async (data: IFittingReceiptSettingsFormOutput) => {
    try {
      setSubmitting(true);
      await OrganisationsApiService.update(organisationData!.uid, {
        fittingReceiptSettings: {
          ...(data.companyStatementTitle && { companyStatementTitle: data.companyStatementTitle }),
          companyStatementBody: data.companyStatementBody,
        },
        updated: getActionTimestampFromUser(userData),
      });
      message.success(t('documents_and_images.fitting_receipt_settings.success'));
      navigate('/organisation-settings/documents-and-images');
    } catch (error) {
      sentryCaptureException(error, 'Updating fitting receipt settings', userData);
      message.error(t('documents_and_images.fitting_receipt_settings.error'));
      setSubmitting(false);
    }
  };

  return (
    <>
      <SharedPageHeader title={t('documents_and_images.fitting_receipt_settings.title')} showBack />
      <SharedCard>
        <SharedForm<IFittingReceiptSettingsFormOutput>
          submitting={submitting}
          onFinish={save}
          name='fitting-receipt-settings'
          fields={[
            {
              label: t('documents_and_images.fitting_receipt_settings.form.company_statement_title'),
              fieldKey: 'companyStatementTitle',
              control: ControlType.TextField,
              type: InputType.Text,
              required: false,
            },
            {
              label: t('documents_and_images.fitting_receipt_settings.form.company_statement_body'),
              fieldKey: 'companyStatementBody',
              control: ControlType.TextArea,
              rows: 5,
              required: true,
            },
          ]}
          existingValue={{ ...organisationData?.fittingReceiptSettings }}
        />
      </SharedCard>
    </>
  );
};

export default FittingReceiptSettings;
