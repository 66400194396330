import { IAppointmentDao } from 'core/api/types/appointment.interface';
import CalendarDailyColumn, { ISharedCalendarColumnResource } from './calendar-daily-column';
import {
  IDomainCalendarResource,
  IDomainOrganisationDataType,
} from 'modules/organisation-settings/organisation-settings-slice';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { CalendarMode } from 'core/constants/calendar-mode';
import { IClinicDao } from 'core/api/types';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';

export interface ISharedCalendarNewAppointment {
  start: string;
  end: string;
}

interface ISharedCalendar {
  timeSlots: string[];
  appointments: IAppointmentDao[];
  unavailability: IHolidayAndUnavailabilityDao[];
  people: IDomainCalendarResource[];
  clinics: IDomainOrganisationDataType<IClinicDao>[];
  highlightedPerson?: string;
  highlightedClinic?: string;
  currentDate: Dayjs;
  loading?: boolean;
  newAppointment?: ISharedCalendarNewAppointment;
  startHour: number;
  showAppointmentMenu?: boolean;
  calendarWrapperRef: React.RefObject<HTMLDivElement>;
  mode: CalendarMode;
}

const SharedCalendarDaily = ({
  timeSlots,
  appointments,
  unavailability,
  people,
  highlightedPerson,
  highlightedClinic,
  currentDate,
  loading,
  newAppointment,
  startHour,
  showAppointmentMenu,
  calendarWrapperRef,
  mode,
  clinics,
}: ISharedCalendar) => {
  const headerHeight = 56;
  const resourceHeaderHeight = 56;
  const minimumSlotHeight = 60;
  const [calendarContainerHeight, setCalendarContainerHeight] = useState<number>(0);
  const [timeSlotHeight, setTimeSlotHeight] = useState<number>(minimumSlotHeight);
  const calendarBodyHeight = timeSlotHeight * timeSlots.length + resourceHeaderHeight;
  const { t } = useTranslation();

  useEffect(() => {
    if (!calendarWrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setCalendarContainerHeight(calendarWrapperRef.current?.clientHeight ?? 0);
    });
    resizeObserver.observe(calendarWrapperRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  });

  useEffect(() => {
    const calendarHeight = calendarContainerHeight - resourceHeaderHeight - headerHeight - (loading ? 4 : 0);
    if (calendarHeight / timeSlots.length < minimumSlotHeight) {
      setTimeSlotHeight(minimumSlotHeight);
    } else {
      setTimeSlotHeight((calendarHeight - (loading ? 4 : 0)) / timeSlots.length);
    }
  }, [calendarContainerHeight, loading, timeSlots.length]);

  const currentTimeLinePosition = () => {
    const minuteHeight = timeSlotHeight / 60;
    const now = dayjs();
    const calculatedMargin =
      resourceHeaderHeight + (now.hour() - startHour) * timeSlotHeight + now.minute() * minuteHeight;
    if (calculatedMargin <= resourceHeaderHeight) {
      return `${resourceHeaderHeight}px`;
    }
    if (calculatedMargin > calendarBodyHeight) {
      return `${calendarBodyHeight - 1}px`;
    }
    return `${calculatedMargin}px`;
  };

  const columns = mode === CalendarMode.PEOPLE ? people : clinics;

  const getResource = (
    column: IDomainCalendarResource | IDomainOrganisationDataType<IClinicDao>
  ): ISharedCalendarColumnResource => {
    if (mode === CalendarMode.PEOPLE) {
      const data = column as IDomainCalendarResource;
      return {
        uid: data.uid,
        label: data.fullName,
        type: 'person',
        details: data,
      };
    } else {
      const data = column as IDomainOrganisationDataType<IClinicDao>;
      return {
        uid: data.uid,
        label: data.name,
        type: 'clinic',
        details: data,
      };
    }
  };

  return (
    <div className='flex grow overflow-y-auto'>
      <div
        className='basis-[56px] shrink-0 grow-0 pr-[8px] border-r bg-white sticky left-0 z-40'
        style={{ height: calendarBodyHeight }}
      >
        <div style={{ height: `${resourceHeaderHeight}px` }} />
        {timeSlots.map((slot) => (
          <div className='relative' style={{ height: `${timeSlotHeight}px` }} key={slot}>
            <p className='text-xs text-gray-500 -top-[8px] absolute right-0'>{slot}</p>
          </div>
        ))}
      </div>
      <div className='grow flex relative bg-white' style={{ height: calendarBodyHeight }}>
        {dayjs().isSame(currentDate, 'day') && columns.length > 0 && (
          <div
            className='absolute w-full h-px bg-red-600 z-20'
            style={{
              top: currentTimeLinePosition(),
            }}
          />
        )}
        <div className='grow flex'>
          {columns.length === 0 && !loading && (
            <Alert className='self-start m-4 w-full' message={t('calendar.no_resources')} type='info' showIcon />
          )}

          {columns.map((column) => (
            <CalendarDailyColumn
              key={column.uid}
              currentDate={currentDate}
              resource={getResource(column)}
              existingAppointments={appointments.filter((app) => {
                if (mode === CalendarMode.PEOPLE) {
                  return app.assignee.uid === column.uid;
                }
                return app.clinic === column.uid;
              })}
              unavailability={unavailability.filter((u) => u.assignee.uid === column.uid)}
              timeSlots={timeSlots}
              timeSlotHeight={timeSlotHeight}
              highlightedPerson={highlightedPerson}
              highlightedClinic={highlightedClinic}
              resourceHeaderHeight={resourceHeaderHeight}
              newAppointment={newAppointment}
              showAppointmentMenu={showAppointmentMenu}
              mode={mode}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharedCalendarDaily;
