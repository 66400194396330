import { App } from 'antd';
import { NoahSyncActionApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import NoahSyncActionCreatedSuccessfullyDialog from './action-created-successfully-dialog';

const NoPatientRecordFoundDialog = (patient: IPatientDao) => {
  const dialog = useDialog();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const { userData } = useUserState();
  const { t } = useTranslation();

  return (
    <SharedDialogBase
      title={'Noah patient record not found'}
      textContent={
        'This patient does not have a Noah record stored within HearLink, continuing with this action will attempt to create a new Noah patient within your site, if you believe this to be a mistake, cancel your action and contact support.'
      }
      customButtons={[
        {
          key: 'cancel',
          props: {
            onClick: () => dialog?.closeDialog(),
            labelKey: 'common.cancel',
            loading,
          },
        },
        {
          key: 'continue',
          props: {
            loading,
            onClick: async () => {
              try {
                setLoading(true);
                const actionTimestamp = getActionTimestampFromUser(userData);
                await NoahSyncActionApiService.set({
                  patientUid: patient.uid,
                  created: actionTimestamp,
                  updated: actionTimestamp,
                  organisationUid: userData?.organisationUid!,
                  uid: uuidv4(),
                });
                dialog?.replaceDialog(<NoahSyncActionCreatedSuccessfullyDialog />);
              } catch (error) {
                message.error(t('patients.patient.information.noah_launch.error'));
              } finally {
                setLoading(false);
              }
            },
            danger: true,
            labelKey: 'common.submit',
            appearance: 'primary',
          },
        },
      ]}
    />
  );
};

export default NoPatientRecordFoundDialog;
