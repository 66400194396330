export enum Title {
  MR = 'mr',
  MRS = 'mrs',
  MISS = 'miss',
  MS = 'ms',
  DR = 'dr',
  OTHER = 'other',
}

export const TitleData = {
  [Title.MR]: {
    value: Title.MR,
    translationLabelKey: 'title.mr',
  },
  [Title.MRS]: {
    value: Title.MRS,
    translationLabelKey: 'title.mrs',
  },
  [Title.MISS]: {
    value: Title.MISS,
    translationLabelKey: 'title.miss',
  },
  [Title.MS]: {
    value: Title.MS,
    translationLabelKey: 'title.ms',
  },
  [Title.DR]: {
    value: Title.DR,
    translationLabelKey: 'title.dr',
  },
  [Title.OTHER]: {
    value: Title.OTHER,
    translationLabelKey: 'title.other',
  },
};

export const TitleOptions = [Title.MR, Title.MRS, Title.MISS, Title.MS, Title.DR, Title.OTHER];
