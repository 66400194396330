import { useNavigate } from 'react-router-dom';
import SharedDialogBase from './dialog-base';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import AppointmentListItem from 'shared/appointment/appointment-list-item';
import { DatePicker, message } from 'antd';
import { AppointmentsApiService } from 'core/api';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import FormItemMimic from 'shared/form/form-item-mimic';
import { useDialog } from 'core/providers/dialog-provider';

interface ISelectAppointmentDialog {
  patientUid: string;
}

const SelectAppointmentDialog = ({ patientUid }: ISelectAppointmentDialog) => {
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { userData } = useUserState();
  const [date, setDate] = useState<Dayjs>(dayjs());
  const dialog = useDialog();
  const navigate = useNavigate();

  const fetchAppointments = useCallback(async () => {
    setLoading(true);
    try {
      const apps = await AppointmentsApiService.fetchPatientAppointments(patientUid, date.format('DD/MM/YYYY'));
      setAppointments(apps);
      setLoading(false);
    } catch (error) {
      message.error(t('patients.patient.patient_appointments.error.description'));
      sentryCaptureException(error, 'Select appointment fetching appointments', userData);
    }
  }, [date, patientUid, t, userData]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  const navigateToCreateOrder = (path: string) => {
    navigate(path);
    dialog?.closeDialog();
  };

  const customContent = () => {
    return (
      <>
        <div className='px-4 pt-4 border-b'>
          <FormItemMimic label={t('dialog.select_appointment.appointment_date')}>
            <DatePicker
              allowClear={false}
              value={date}
              onChange={(date) => setDate(date)}
              maxDate={dayjs()}
              className='w-full'
              format={'DD/MM/YYYY'}
            />
          </FormItemMimic>
        </div>
        {loading ? (
          <p className='p-4 text-gray-400'>{t('dialog.select_appointment.loading')}</p>
        ) : (
          <div className='overflow-y-auto'>
            {appointments.map((app) => (
              <AppointmentListItem
                key={app.uid}
                appointment={app}
                showMenu={false}
                onClick={() => navigateToCreateOrder(`/orders/create?appointment=${app.uid}`)}
              />
            ))}
            {appointments.length === 0 && (
              <p className='p-4 text-gray-400'>{t('dialog.select_appointment.no_appointments')}</p>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <SharedDialogBase
      title={t('dialog.select_appointment.title')}
      subtitle={t('dialog.select_appointment.subtitle')}
      customContentTemplate={customContent()}
      customButtons={[
        {
          key: 'close',
          props: {
            onClick: () => dialog?.closeDialog(),
            labelKey: 'common.close',
          },
        },
        {
          key: 'continue',
          props: {
            onClick: () => navigateToCreateOrder(`/orders/create?patient=${patientUid}`),
            labelKey: 'dialog.select_appointment.continue',
            appearance: 'primary',
          },
        },
      ]}
    />
  );
};

export default SelectAppointmentDialog;
