export enum CustomFormTemplateComponentType {
  RICH_TEXT = 'richText',
  FIELD = 'field',
}

export const CustomFormTemplateComponentTypeData = {
  [CustomFormTemplateComponentType.RICH_TEXT]: {
    value: CustomFormTemplateComponentType.RICH_TEXT,
    translationLabelKey: 'custom_form_component_type.rich_text',
  },
  [CustomFormTemplateComponentType.FIELD]: {
    value: CustomFormTemplateComponentType.FIELD,
    translationLabelKey: 'custom_form_component_type.field',
  },
};

export const CustomFormTemplateComponentTypeOptions = [
  CustomFormTemplateComponentType.RICH_TEXT,
  CustomFormTemplateComponentType.FIELD,
];
