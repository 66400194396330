import { Popover } from 'antd';
import clsx from 'clsx';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { AppointmentLocation, AppointmentLocationData } from 'core/constants/appointment-location';
import { useResponsiveState } from 'core/providers/responsive-provider';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppointmentMenu from 'shared/appointment/appointment-menu';
import { getTimeStringFromTimestamp, getWeeklyAppointmentStyle } from 'shared/helpers/appointment-helpers';
import { StoreState } from 'store';

interface ISharedCalendarAppointment {
  appointment: IAppointmentDao;
  width: number;
  heightPercent: number;
  topPercent: number;
  showAppointmentMenu: boolean;
}

const SharedCalendarWeeklyAppointment = ({
  appointment,
  width,
  heightPercent,
  topPercent,
  showAppointmentMenu,
}: ISharedCalendarAppointment) => {
  const { primary } = useTheme();
  const { t } = useTranslation();

  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const appointmentTypeDetail = appointmentTypeState?.data.find((type) => type.uid === appointment.type);
  const outcome = appointmentTypeDetail?.outcomes.find((outcome) => outcome.key === appointment.outcome);
  const startTimeString = dayjs(appointment.startDateTime.toDate()).format('HH:mm');
  const endTimeString = getTimeStringFromTimestamp(appointment.endDateTime);
  const clinic = useSelector((state: StoreState) => OrganisationSettingsSlice.selectClinic(state, appointment.clinic));
  const location = AppointmentLocationData[appointment.location];
  const { organisationData } = useUserState();
  const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
  const calendarEnd = dayjs(organisationData?.calendar.endTime.toDate());
  const { isMobile } = useResponsiveState();
  const [popoverTrigger, setPopoverTrigger] = useState<'hover' | 'focus' | 'click' | 'contextMenu'>('hover');
  const style = getWeeklyAppointmentStyle(
    calendarStart,
    calendarEnd,
    getTimeStringFromTimestamp(appointment.startDateTime),
    getTimeStringFromTimestamp(appointment.endDateTime),
    appointment.cancelled,
    heightPercent,
    topPercent,
    width,
    appointmentTypeDetail?.colour ?? primary.bg
  );
  const wx = parseInt(style.width);
  const isTiny = wx < 20;

  useEffect(() => {
    if (isMobile) {
      setPopoverTrigger('click');
    }
  }, [isMobile]);

  const getAddress = () => {
    let address = appointment.patient.address?.formattedAddress;
    if (appointment.location === AppointmentLocation.CLINIC) {
      address = clinic?.address.formattedAddress;
    }
    return address ? <p className='body-xs'>{address}</p> : <></>;
  };

  const getTypeAndLocation = () => {
    const values = [appointmentTypeDetail?.name, location ? t(location?.translationLabelKey) : undefined].filter(
      Boolean
    );
    return values.length === 0 ? <></> : <p className='body-xs opacity-70'>{values.join(' - ')}</p>;
  };

  const appointmentContent = (contentClassName?: string, showNotes?: boolean) => (
    <>
      {outcome && (
        <span
          className='flex w-full px-2 py-1.5 font-semibold body-xs'
          style={{ backgroundColor: outcome.bannerColour }}
        >
          {outcome.bannerLabel}
        </span>
      )}
      <div className={contentClassName}>
        <p className={clsx('font-semibold', isTiny && 'body-xs')}>{appointment.patient.fullName}</p>
        <p className='body-xs'>{`${startTimeString} - ${endTimeString}`}</p>
        {getAddress()}
        {getTypeAndLocation()}
        {clinic && <p className='body-xs opacity-70'>{clinic.name}</p>}
        {showNotes && appointment.additionalNote && (
          <div className='mt-3 body-xs'>
            <p className='text-gray-500'>{t('calendar.add_edit_appointment.form.additional_information')}</p>
            <p>{appointment.additionalNote}</p>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div
      className={clsx(
        'group absolute w-full rounded-md shadow-sm text-white z-10 overflow-y-auto hide-scrollbar bg-red-500 flex justify-between items-start',
        appointment.cancelled && 'line-through'
      )}
      style={style}
    >
      <div className='relative w-full'>
        {showAppointmentMenu && (
          <AppointmentMenu
            appointment={appointment}
            className='absolute top-0 right-0 md:hidden md:group-hover:block'
          />
        )}
        <Popover content={appointmentContent(undefined, true)} trigger={popoverTrigger}>
          <div>{appointmentContent(isTiny ? 'p-1' : 'p-2')}</div>
        </Popover>
      </div>
    </div>
  );
};

export default SharedCalendarWeeklyAppointment;
