import { App } from 'antd';
import { StockApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IStockAccessoryDao, IStockDao } from 'core/api/types/stock.interface';
import { EarData, EarOptions } from 'core/constants/ear';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface IEditAllocationDialog {
  patient: IPatientDao;
  stockItem: IStockDao;
  multiAccessoryAllocation: boolean;
}

interface IEditAllocationDialogFormOutput {
  allocatedDate: Dayjs;
  side?: 'left' | 'right';
}

const EditAllocationDialog = ({ patient, stockItem, multiAccessoryAllocation }: IEditAllocationDialog) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();

  const formFields: ISharedField[] = [
    {
      fieldKey: 'allocatedDate',
      control: ControlType.DatePicker,
      label: t('stock_management.stock_allocation.form.allocated_date'),
      required: true,
      fullWidth: true,
    },
  ];

  if (stockItem.type === 'hearingAid') {
    formFields.push({
      fieldKey: 'side',
      control: ControlType.RadioButton,
      options: EarOptions.map((ear) => {
        const data = EarData[ear];
        return {
          value: data.value,
          label: t(data.translationLabelKey),
        };
      }),
      label: t('stock_management.stock_allocation.form.side'),
      required: true,
    });
  }

  const onSubmit = async (values: IEditAllocationDialogFormOutput) => {
    setLoading(true);
    try {
      let accessoryData: IStockAccessoryDao | undefined;
      if (stockItem.type === 'accessory') {
        if (multiAccessoryAllocation) {
          const snap = await StockApiService.get(stockItem.uid);
          accessoryData = snap.data() as IStockAccessoryDao | undefined;
        } else {
          accessoryData = stockItem;
        }
      }

      if (stockItem.type === 'accessory') {
        const allocationIndex = accessoryData?.allocation.allocations.findIndex(
          (allocation) =>
            allocation.to.uid === patient.uid && allocation.at.isEqual(stockItem.allocation.allocations[0].at)
        );
        await StockApiService.update(stockItem.uid, {
          updated: getActionTimestampFromUser(userData),
          allocation: {
            ...stockItem.allocation,
            allocations: accessoryData?.allocation.allocations.map((allocation, index) => {
              if (index === allocationIndex) {
                return {
                  ...allocation,
                  at: Timestamp.fromDate(values.allocatedDate.toDate()),
                };
              } else {
                return allocation;
              }
            }),
          },
        });
      } else {
        await StockApiService.update(stockItem.uid, {
          updated: getActionTimestampFromUser(userData),
          allocated: {
            ...stockItem.allocated,
            at: Timestamp.fromDate(values.allocatedDate.toDate()),
            side: values.side,
          },
        });
      }
      message.success(t('patients.patient.products.edit_allocation_dialog.success'));
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      message.error(t('patients.patient.products.edit_allocation_dialog.error'));
    }
  };

  const allocation = stockItem.type === 'hearingAid' ? stockItem.allocated : stockItem.allocation.allocations[0];

  const content = (
    <SharedForm<IEditAllocationDialogFormOutput>
      submitting={loading}
      name='edit-allocation-form'
      fields={formFields}
      onFinish={onSubmit}
      cancelButton={{
        appearance: 'text',
        labelKey: 'common.cancel',
        onClick: () => dialog?.closeDialog(),
      }}
      existingValue={{
        allocatedDate: allocation ? dayjs(allocation.at.toDate()) : dayjs(),
        side: stockItem.type === 'hearingAid' ? allocation?.side : undefined,
      }}
    />
  );

  return (
    <SharedDialogBase
      title={t('patients.patient.products.edit_allocation_dialog.title')}
      customContentTemplate={content}
      showButtons={false}
    />
  );
};

export default EditAllocationDialog;
