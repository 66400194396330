import { App } from 'antd';
import { StockApiService } from 'core/api';
import { StockStatus } from 'core/constants/stock-status';
import { useTablesState } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const StockManagementOverviewCounts = () => {
  const { t } = useTranslation();
  const [hearingAidCount, setHearingAidCount] = useState(0);
  const [accessoriesCount, setAccessoriesCount] = useState(0);
  const [loadingCounts, setLoadingCounts] = useState(true);
  const { message } = App.useApp();
  const { userData } = useUserState();
  const { register, deregister } = useTablesState();

  const getCounts = useCallback(async () => {
    if (userData?.organisationUid) {
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        where('status', 'not-in', [StockStatus.SOLD, StockStatus.RETURNED_TO_SUPPLIER]),
      ];
      try {
        setLoadingCounts(true);
        const [aidSnap, accSnap] = await Promise.all([
          StockApiService.getCollectionCount([...baseConstraints, where('type', '==', 'hearingAid')]),
          StockApiService.getCollectionCount([...baseConstraints, where('type', '==', 'accessory')]),
        ]);
        setHearingAidCount(aidSnap.data().count);
        setAccessoriesCount(accSnap.data().count);
        setLoadingCounts(false);
      } catch (error) {
        message.error(t('stock_management.overview.fetch_counts_error'));
        sentryCaptureException(error, 'fetching stock counts', userData);
      }
    }
  }, [message, t, userData]);

  useEffect(() => {
    register({ key: 'stockManagementCounts', refreshTable: getCounts });
    return () => {
      deregister('stockManagementCounts');
    };
  }, [register, getCounts, deregister]);

  useEffect(() => {
    getCounts();
  }, [getCounts]);

  const counts = [
    {
      key: 'hearing-aids',
      labelKey: 'products_and_services.hearing_aids',
      count: hearingAidCount,
    },
    {
      key: 'accessories',
      labelKey: 'products_and_services.accessories',
      count: accessoriesCount,
    },
  ];

  return (
    <SharedCard innerClassName='grid grid-cols-2'>
      {counts.map((count) => (
        <Link key={count.key} to={count.key} className='border-r p-4 last:border-r-0 hover:bg-gray-50 cursor-pointer'>
          <p className='text-gray-500 mb-1'>{t(count.labelKey)}</p>
          {loadingCounts ? (
            <SkeletonElement height='60px' width='70px' />
          ) : (
            <p className='text-6xl font-extralight'>{count.count}</p>
          )}
        </Link>
      ))}
    </SharedCard>
  );
};

export default StockManagementOverviewCounts;
